import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Images from '../../assets/images';
import Actions from '../../redux/actions';

function ModalProduct({ hide, organization, product }) {
    const cityInfo = useSelector(state => state.Content.city);
    const productAllInfo = useSelector(state => state.AreaWork.organizationProduct);
    const dispatch = useDispatch();
    const specificationsAllInfo = product ? product.specifications : [];
    const [selectedSpecifications, setSpecifications] = useState({});

    const handleAddItem = (event) => {
        const compositionGuid = event.target.getAttribute('id');
        const localVar = {};
        specificationsAllInfo.forEach(item => {
            let quantityTotal = 0;
            item.composition.forEach(item_2 => {
                const quantity = item_2.quantityCart || 0;
                if (item_2.guid === compositionGuid) {
                    item_2.quantityCart = quantity + 1;
                    quantityTotal = quantityTotal + item_2.quantityCart;
                }
                localVar[item_2.guid] = { quantityTotal, productId: item_2.guid, productCount: item_2.quantityCart };
            });
        });
        setSpecifications(localVar);
    };

    const handleRemoveItem = (event) => {
        const compositionGuid = event.target.getAttribute('id');
        const localVar = {};
        specificationsAllInfo.forEach(item => {
            let quantityTotal = 0;
            item.composition.forEach(item_2 => {
                const quantity = item_2.quantityCart || 0;
                if (item_2.guid === compositionGuid && quantity > 0) {
                    item_2.quantityCart = quantity - 1;
                    quantityTotal = quantityTotal + item_2.quantityCart;
                }
                localVar[item_2.guid] = { quantityTotal, productId: item_2.guid, productCount: item_2.quantityCart };
            });
        });
        setSpecifications(localVar);
    };

    return (
        <div className="modal-container">
            <div className="modal-container-product">
                <button className="btn btn-light" onClick={hide}>&#10006;</button><br/>
                <div className="row">
                    <div className="col">
                        <div className="modal-product-image">
                            {product?.image && (
                                <img src={`/uploads/images/products/${product.image}`} alt={product.name} />
                            )}
                        </div>
                    </div>
                    <div className="col">
                        <label className="modal-product-name">
                            {product?.name}, <span className="text-muted">{product?.weight} гр</span>
                        </label>
                        <br/>
                        {product?.description}<br/><br/>
                        {specificationsAllInfo.map((item, index) => (
                            <div key={`specification_${index}`}>
                                <label className="specification-name">{item.name}</label><br/>
                                {item.composition.map((item_2, index) => (
                                    <div className="row" key={`composition_${index}`}>
                                        <div className="col-xs-12 col-sm-8 col-md-8">
                                            <label className="text-muted">
                                                {item_2.name}, {item_2.weight} гр - <span>{item_2.price} руб</span>
                                            </label>
                                        </div>
                                        <div className="modal-specifications-buttons col">
                                            <button className="modal-add-specification btn btn-light"
                                                disabled={
                                                    item.min > 0 && selectedSpecifications[item_2.guid]?.quantityTotal === item.min || 
                                                    !selectedSpecifications[item_2.guid] ||
                                                    !selectedSpecifications[item_2.guid]?.productCount
                                                }
                                                onClick={handleRemoveItem}
                                                id={item_2.guid}
                                            >
                                                —
                                            </button>
                                            <div className="modal-label-quantity">
                                                {selectedSpecifications[item_2.guid]?.productCount || 0}
                                            </div>
                                            <button className="modal-add-specification btn btn-light"
                                                disabled={
                                                    item.max > 0 && selectedSpecifications[item_2.guid]?.quantityTotal === item.max
                                                }
                                                onClick={handleAddItem}
                                                id={item_2.guid}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <br/>
                        <div className="product-price">
                            {product?.price} руб
                            {product?.fakeprice > 0 && (
                                <label className="product-fake-price text-muted">
                                    {product.fakeprice} руб
                                </label>
                            )}
                        </div>
                        <br/>
                        <center>
                            <button
                                className="modal-add-to-cart btn btn-light"
                                onClick={() => {
                                    const additionsItems = Object.values(selectedSpecifications).filter(item => item.productCount > 0)
                                        .map(item => ({
                                            productId: item.productId,
                                            productCount: item.productCount
                                        }));
                                    const data = {
                                        organizationId: organization,
                                        productId: product.guid,
                                        productCount: 1,
                                        additions: additionsItems
                                    };
                                    dispatch(Actions.ActionsFor1C.addToCart(data, cityInfo.guid));
                                }}
                            >
                                Добавить в корзину
                            </button>
                        </center>
                    </div>
                </div><br/><br/>
                <div className="modal-product-info row">
                    <h5>Пищевая ценность <span className="text-muted">на 100 гр</span></h5>
                </div>
                <div className="modal-product-info row">
                    <div className="col">Белки</div>
                    <div className="col text-muted">
                        {product?.protein || 'Нет информации'}
                    </div>
                </div>
                <div className="modal-product-info row">
                    <div className="col">Жиры</div>
                    <div className="col text-muted">
                        {product?.fat || 'Нет информации'}
                    </div>
                </div>
                <div className="modal-product-info row">
                    <div className="col">Углеводы</div>
                    <div className="col text-muted">
                        {product?.carbohydrate || 'Нет информации'}
                    </div>
                </div>
                <div className="modal-product-info row">
                    <div className="col">Калорийность</div>
                    <div className="col text-muted">
                        {product?.calories || 'Нет информации'}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalProduct;