export const getCookie = (name) => {
    const nameEQ = name + '=';
    const docCookies = document.cookie.split(';');
    for (let i = 0; i < docCookies.length; i++) {
        let docCookie = docCookies[i];
        while (docCookie.charAt(0) === ' ') docCookie = docCookie.substring(1, docCookie.length);
        if (docCookie.indexOf(nameEQ) === 0) return docCookie.substring(nameEQ.length, docCookie.length);
    }
    return null;
};

export const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/;`;
};