import React, { useState } from 'react'
import ModalSidebar from '../ModalSidebar'

function Sidebar() {
    const [selectVisible, setSelectVisible] = useState(0)
    return(
        <div className="header-sidebar">
            <button className="btn btn-light" onClick={() => setSelectVisible(selectVisible + 1)}>
                <span className="navbar-toggler-icon"></span>
            </button>
            { selectVisible ? <ModalSidebar hide={()=>{setSelectVisible(false)}} /> : null }
        </div>
    )
}

export default Sidebar