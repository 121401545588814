import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ModalSelectCity from '../ModalSelectCity';

function SelectCity() {
    const [selectVisible, setSelectVisible] = useState(false);
    const selectedCity = useSelector(state => state.Content.city);
    const location = useLocation();

    const toggleSelectVisible = () => {
        setSelectVisible(prev => !prev);
    };

    var shouldShowModal = selectVisible || location.pathname === '/';

    return (
        <>
            <div className="header-select-city">
                <div onClick={toggleSelectVisible} className="dropdown">
                    <label>{selectedCity ? selectedCity.name : 'Выбор города'}</label>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1.5L8 8.5L1 1.5" />
                    </svg>
                </div>
            </div>
            {shouldShowModal && (
                <ModalSelectCity hide={() => setSelectVisible(false)} />
            )}
        </>
    );
}

export default SelectCity;