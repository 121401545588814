import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Actions from '../../../redux/actions'
import Components from '../../../components'
import Select from 'react-select'
import Moment from 'moment'
import InputMask from 'react-input-mask'
import ModalSms from '../ModalSms'

class Checkout extends Component {
    constructor (props) {
        super(props)
        let userLocalInfo = JSON.parse(window.localStorage.getItem('userLocal'))
        let userName = userLocalInfo && userLocalInfo.name != null ? userLocalInfo.name : null
        let userPhone = userLocalInfo && userLocalInfo.number != null ? userLocalInfo.number : null

        this.state = {
            name: userName,
            phone: userPhone,
            apartment: null,
            entrance: null,
            floor: null,
            comment: null,
            paymentType: 1,
            quantityOfPeople: 1,
            organizationSelect: null,
            code: 0,
            active: 0,
            modalVisible: false
        }
    }

    getDates(startDate, stopDate) {
        var dateArray = new Array()
        var currentDate = startDate
        while (currentDate <= stopDate) {
            dateArray.push(currentDate)
            currentDate = currentDate.addDays(1)
        }
        return dateArray
    }


    render(){
        Date.prototype.addDays = function(days) {
            var dat = new Date(this.valueOf())
            dat.setDate(dat.getDate() + days)
            return dat
        }

        const { datesCourier = [], timeCourier = [], addressPickup = [], datesPickup = [], timePickup = [] } = this.state

        const paymentTypesSwitch = event => {
            this.setState({active: event.target.dataset.index})
        }

        const tabCourier = (
            <div className="tab-courier"><br/>
                <div className="select-address-courier" onChange={(event) => {if (event.target.value?.length > 2)
                    this.props.getStreetsByMask(event.target.value)}}>
                    <Select placeholder='Адрес' noOptionsMessage={() => 'Введите адрес...'}
                    options={ this.props.deliveryStreetsByMask != null ?
                        this.props.deliveryStreetsByMask.map((item) => {
                            return { label: item.name, value: item.addressId }}) : []
                    } onChange={(event) => {
                        if (event.value != null) {
                            this.setState({streetСourier: event.value})
                            if (this.state.homeСourier != null) {
                                this.props.setOrderAddress(Number(event.value), this.state.homeСourier, 0, 0)
                            }
                            this.setState({datesCourier: []})
                        }}
                    }/>
                </div><br/>
                <div className="row">
                    <div className="col-6">
                        <input autoComplete={'off'} className="form-control" id='number-home' placeholder='Дом'
                            onBlur={(event) => {
                                if (event.target.value != null) {
                                    this.setState({homeСourier: event.target.value})
                                    this.props.setOrderAddress(Number(this.state.streetСourier), event.target.value, 0, 0)
                                }
                            }}
                        />
                    </div>
                    <div className="col-6">
                        <input autoComplete={'off'} className="form-control" id='entrance' placeholder='Подъезд'
                            onBlur={(event) => {if (event.target.value != null)
                            {this.setState({entrance: event.target.value})}}}/>
                    </div>
                </div><br/>
                <div className="row">
                    <div className="col-6">
                        <input autoComplete={'off'} className="form-control" id='floor' placeholder='Этаж'
                            onBlur={(event) => {if (event.target.value != null)
                            {this.setState({floor: event.target.value})}}}/>
                    </div>
                    <div className="col-6">
                        <input autoComplete={'off'} className="form-control" id='apartment' placeholder='Квартира'
                            onBlur={(event) => {if (event.target.value != null)
                            {this.setState({apartment: event.target.value})}}}/>
                    </div>
                </div><br/>
                <textarea autoComplete={'off'} className="form-control" id='comment' placeholder='Комментарий'
                    onBlur={(event) => {if (event.target.value != null)
                    {this.setState({comment: event.target.value})}}}/>
                {this.props.checkoutAddressError != null ? this.props.checkoutAddressError.map((item, index) => {
                    const result = this.props.cartsInfo.find(item_2 => item_2.organizationInfo.guid == item)
                    return(<Fragment><br/>
                    <label className="alert alert-danger"><b>{result.organizationInfo.name}</b> не
                        осуществляет доставку на данный адрес. Пожалуйста, укажите другой адрес.</label>
                    </Fragment>)
                }) : null}
                {this.props.checkoutAddressError != null || this.props.checkoutAddressError == null && !this.state.streetСourier && !this.state.homeСourier ||
                this.props.checkoutAddressError == null && !this.state.streetСourier ||
                this.props.checkoutAddressError == null && !this.state.homeСourier ? null :
                    <Fragment><br/><br/>
                    <label className="checkout-title-block">Время получения</label>
                    {Array.isArray(this.props.cartsInfo) && this.props.cartsInfo?.length > 0 &&
                        (this.props.cartsInfo.map((item, index) => {
                        return (
                        <div className="date-time" key={'courier_'+item.organizationInfo.guid}>
                            <br/>
                            <label className="cart-organization-name">{item.organizationInfo.name}</label>
                            <div className="row" onClick={() => {this.setState({organizationSelect: item.organizationInfo.guid})}} >
                                <div className="col-6">
                                    <Select placeholder='Дата' noOptionsMessage={() => 'Выберите дату'}
                                        options={this.getDates(new Date(), (new Date()).addDays(13)).map((item_2) => {
                                            return { label: Moment(item_2).format('DD.MM.yyyy'), value: Moment(item_2).unix()}})}
                                        onChange={event => {
                                            if (event.value != null) {
                                                this.props.getPeriodsTime(event.value, item.organizationInfo.guid)
                                                datesCourier[index] = event.value
                                                this.setState({datesCourier})
                                                this.setState({
                                                    timeCourier: timeCourier.filter((item_2, index_2) =>
                                                        index_2 != index
                                                    )
                                                })
                                            }
                                        }}
                                    />
                                    {this.state.organizationSelect === item.organizationInfo.guid && this.props.checkoutDateError && this.props.checkoutDateError != null ?
                                    <label className="text-danger">На выбранную дату доставка невозможна. Пожалуйста, выберите другую дату.</label>
                                    : null}
                                </div>
                                <div className="col-6" onClick={() => {
                                    if (this.state.organizationSelect === item.organizationInfo.guid) {
                                        this.props.getPeriodsTime(datesCourier[index], item.organizationInfo.guid)
                                    }
                                }}>
                                    <Select placeholder='Время' noOptionsMessage={() => 'Выберите время'}
                                        options={this.state.organizationSelect === item.organizationInfo.guid && this.props.deliveryPeriodsTime ?
                                            this.props.deliveryPeriodsTime.map((item_2) => {return { label: item_2.view, value: item_2.periodId }}) : []}
                                        onChange={(event) => { if (event.value != null)
                                            this.props.setOrderDateTime(item.organizationInfo.guid, datesCourier[index], event.value)
                                            timeCourier[index] = event.value
                                            this.setState({timeCourier})
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        )
                    }))}
                </Fragment>}
            </div>
        )

        const tabPickupPoint = (
            <div className="tab-pickup"><br/>
                <textarea autoComplete={'off'} className="form-control" id='comment' placeholder='Комментарий'
                    onBlur={(event) => {if (event.target.value != null)
                    {this.setState({comment: event.target.value})}}}/><br/>
                <label className="checkout-title-block">Время получения</label>
                {Array.isArray(this.props.cartsInfo) && this.props.cartsInfo?.length > 0 && (
                    this.props.cartsInfo.map((item, index) => {
                    return (item.organizationInfo && (
                        <Fragment>
                            <div className="date-time" key={'pickup_'+index}><br/>
                                <label className="cart-organization-name">{item.organizationInfo.name}</label>
                                <Select placeholder='Пункт самовывоза' noOptionsMessage={() => 'Выберите пункт самовывоза'}
                                    options={item.organizationInfo.branches != null ? item.organizationInfo.branches.map((item_2) => {
                                        return { label: item_2.street+' '+item_2.house, value: item_2.addressId+'/'+item_2.house }}) : []}
                                    onChange={event => {
                                        if (event.value != null) {
                                            let newValue = event.value.split('/')
                                            this.props.setOrderAddress(Number(newValue[0]), String(newValue[1]), 1, item.organizationInfo.guid)
                                            addressPickup[index] = event.value
                                            this.setState({addressPickup})
                                            this.setState({
                                                timePickup: timePickup.filter((item_2, index_2) =>
                                                    index_2 != index
                                                )
                                            })
                                        }
                                    }}
                                /><br/>
                                <div className="row" onClick={() => {this.setState({organizationSelect: item.organizationInfo.guid})}}>
                                    <div className="col-6">
                                        <Select placeholder='Дата' noOptionsMessage={() => 'Выберите дату'}
                                            options={this.getDates(new Date(), (new Date()).addDays(13)).map((item_2) => {
                                                return { label: Moment(item_2).format('DD.MM.yyyy'), value: Moment(item_2).unix()}})}
                                            onChange={event => {
                                                if (event.value != null) {
                                                    this.props.getPeriodsTime(event.value, item.organizationInfo.guid)
                                                    datesPickup[index] = event.value
                                                    this.setState({datesPickup})
                                                    this.setState({
                                                        timePickup: timePickup.filter((item_2, index_2) =>
                                                            index_2 != index
                                                        )
                                                    })
                                                }
                                            }}
                                        />
                                        {this.state.organizationSelect === item.organizationInfo.guid && this.props.checkoutDateError && this.props.checkoutDateError != null ?
                                        <label className="text-danger">На выбранную дату доставка невозможна. Пожалуйста, выберите другую дату.</label>
                                        : null}
                                    </div>
                                    <div className="col-6" onClick={() => {
                                        if (this.state.organizationSelect === item.organizationInfo.guid) {
                                            this.props.getPeriodsTime(datesPickup[index], item.organizationInfo.guid)
                                        }
                                    }}>
                                        <Select placeholder='Время' noOptionsMessage={() => 'Выберите время'}
                                            options={this.state.organizationSelect === item.organizationInfo.guid && this.props.deliveryPeriodsTime ?
                                                this.props.deliveryPeriodsTime.map((item_2) => {return { label: item_2.view, value: item_2.periodId }}) : []}
                                            onChange={(event) => { if (event.value != null)
                                                this.props.setOrderDateTime(item.organizationInfo.guid, datesPickup[index], event.value)
                                                timePickup[index] = event.value
                                                this.setState({timePickup})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fragment>)
                    )
                }))}
            </div>
        )

        const paymentTypesItems = ['Наличные', 'Карта']

        return (this.props.carts && this.props.carts?.length > 0 ?
            <div className="col">
                <label className="checkout-title-block">Контакты</label>
                <div className="row">
                    <div className="col-6">
                        <input
                            autoComplete={'off'}
                            className="form-control"
                            placeholder='Имя'
                            defaultValue={this.state.name}
                            onBlur={(event) => {
                                if (event.target.value?.length > 0) {
                                    this.setState({name: event.target.value})
                                }
                                if (this.state.phone && this.state.phone?.length == 10 && event.target.value?.length > 0) {
                                    this.props.getUserCheck(event.target.value, this.state.phone, 0)
                                }
                            }}
                        />
                        {this.props.checkoutUserError != null ? <label className="text-danger">Введите имя</label> : null}
                    </div>
                    <div className="col-6">
                        <InputMask mask="+7 (999) 999-99-99" maskChar={null} defaultValue={this.state.phone} placeholder="Номер телефона"
                        className="form-control" onBlur={(event) => {
                            const valueString = event.target.value.substr(2).replace(/\D/g, '')
                            this.setState({phone: valueString})
                            if (this.state.name != null) {
                                this.props.getUserCheck(this.state.name, valueString, 0)
                            } else {
                                this.props.getUserCheck(null, valueString, 0)
                            }
                        }}/>
                        {this.props.checkoutPermissionSendSms && this.props.checkoutPermissionSendSms != true &&
                            this.props.checkoutStatusSendSms && this.props.checkoutStatusSendSms != true ?
                            <label className="text-danger">Некорректный номер телефона</label> : null}
                    </div>
                </div><br/>
                <div className="row">
                    <div className="col-6">
                        <label>Количество персон</label>
                        <input
                            onChange={(event) => {this.setState({quantityOfPeople: event.target.value})}}
                            defaultValue={this.state.quantityOfPeople || ''}
                            autoComplete={'off'}
                            type={'number'}
                            min={1}
                            max={99}
                            className="form-control"
                        />
                    </div>
                </div><br/><br/>

                {this.state.phone == null || this.state.phone?.length < 10 ? null :
                <Fragment>
                    <label className="checkout-title-block">Способ доставки</label>
                    <Components.TabsDelivery items={[{ title: 'Курьер', content: tabCourier }, { title: 'Самовывоз', content: tabPickupPoint }]} /><br/><br/>
                    <label className="checkout-title-block">Способ оплаты</label>
                    <div className="switch-title">
                        {paymentTypesItems.map((item, index) => (
                            <button className={'btn btn-light'+(index == this.state.active ? ' active' : '')} onClick={paymentTypesSwitch}
                            data-index={index} key={'payment_'+index}>{item}</button>
                        ))}
                    </div>
                    {this.props.cartsInfo?.length == timeCourier?.length && this.props.carts?.length > 0 ||
                    this.props.cartsInfo?.length == timePickup?.length && this.props.carts?.length > 0 ?
                        <div className="checkout-done-info"><button className="checkout-done-button btn btn-light btn-lg"
                        onClick={() => {
                            let userLocalInfo = JSON.parse(window.localStorage.getItem('userLocal'))
                            let authLocal = JSON.parse(window.localStorage.getItem('auth'))
                            let data = {
                                phone: this.state.phone,
                                soonAsPossible: Number(this.state.soonAsPossible),
                                quantityOfPeople: Number(this.state.quantityOfPeople),
                                paymentType: this.state.paymentType,
                                entrance: this.state.entrance,
                                floor: this.state.floor,
                                apartment: this.state.apartment,
                                comment: this.state.comment
                            }
                            if (userLocalInfo != null && authLocal == true) {
                                this.props.setOrderDone(data)
                            } else {
                                this.props.setOrderAudit(data)
                                this.state.modalVisible = true
                            }
                        }}>
                            Оформить заказ
                        </button><br/><br/>
                        {this.state.modalVisible == true ? <ModalSms hide={()=>{ this.setState({ modalVisible: false})}} dataAudit={{
                            phone: this.state.phone,
                            soonAsPossible: Number(this.state.soonAsPossible),
                            quantityOfPeople: Number(this.state.quantityOfPeople),
                            paymentType: this.state.paymentType,
                            entrance: this.state.entrance,
                            floor: this.state.floor,
                            apartment: this.state.apartment,
                            comment: this.state.comment
                        }} /> : null}
                        </div>
                    : null}
                        {this.props.checkoutTimeError != null ? this.props.checkoutTimeError.map((item, index) => {
                            const result = this.props.cartsInfo.find(item_2 => item_2.organizationInfo.guid == item.organizationId)
                            return(<Fragment key={'time_error'+index}><br/>
                            <label className="alert alert-danger"><b>{result.organizationInfo.name}</b> не
                            сможет выполнить заказ.<br/>{item.message}</label>
                            </Fragment>)
                        }) : null}
                </Fragment>}
            <br/><br/></div> : ''
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    carts: state.AreaWork.carts,
    deliveryStreetsByMask: state.Cart.deliveryStreetsByMask,
    deliveryPeriodsTime: state.Cart.deliveryPeriodsTime,
    checkoutUserError: state.Cart.checkoutUserError,
    checkoutAddressError: state.Cart.checkoutAddressError,
    checkoutDateError: state.Cart.checkoutDateError,
    checkoutTimeError: state.Cart.checkoutTimeError,
    checkoutPermissionSendSms: state.Cart.checkoutPermissionSendSms,
    checkoutStatusSendSms: state.Cart.checkoutStatusSendSms,
    checkoutValidCode: state.Cart.checkoutValidCode,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getUserCheck: (data_1, data_2, data_3) => {
            dispatch(Actions.ActionsFor1C.getUserCheck(data_1, data_2, data_3))
        },
        getStreetsByMask: (data) => {
            dispatch(Actions.ActionsForServer.getStreetsByMask(data))
        },
        setOrderAddress: (data_1, data_2, data_3, data_4) => {
            dispatch(Actions.ActionsFor1C.setOrderAddress(data_1, data_2, data_3, data_4))
        },
        getPeriodsTime: (data_1, data_2) => {
            dispatch(Actions.ActionsFor1C.getPeriodsTime(data_1, data_2))
        },
        setOrderDateTime: (data_1, data_2, data_3) => {
            dispatch(Actions.ActionsFor1C.setOrderDateTime(data_1, data_2, data_3))
        },
        setOrderAudit: (data) => {
            dispatch(Actions.ActionsFor1C.setOrderAudit(data))
        },
        setOrderDone: (data) => {
            dispatch(Actions.ActionsFor1C.setOrderDone(data))
        },
        checkSmsCode: (data_1, data_2) => {
            dispatch(Actions.ActionsForServer.checkSmsCode(data_1, data_2))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)