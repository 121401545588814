import React, { Component } from 'react'
import Images from '../assets/images'

class Success extends Component {
    render () {
        return (
            <div className="body-container">
                <div className="container">
                    <div className="success-container">
                        <div className="success-image"><img src={Images.Feedback}/></div>
                        <label>Заказ оформлен. Спасибо!</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default Success