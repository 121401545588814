import React, { useState, useEffect } from 'react';

function Slider({ items, pathImage }) {
    const [activeIndex, setActiveIndex] = useState(0);

    const images = items?.images ?? [];
    const imageCount = images?.length;

    useEffect(() => {
        if (imageCount > 1) {
            const intervalId = setInterval(() => {
                setActiveIndex((current) =>
                    current === imageCount - 1 ? 0 : current + 1
                );
            }, 9000);

            return () => clearInterval(intervalId);
        }
    }, [imageCount]);

    if (imageCount === 0) {
        return null;
    }

    const prevImgIndex = activeIndex === 0 ? imageCount - 1 : activeIndex - 1;
    const nextImgIndex = activeIndex === imageCount - 1 ? 0 : activeIndex + 1;

    return (
        <div className="slider">
            <div className="slider-list">
                {imageCount > 2 && (
                    <div className="slider-element slider-prev">
                        <img src={pathImage + images[prevImgIndex]} alt="Previous" />
                    </div>
                )}
                <div className="slider-element">
                    <img src={pathImage + images[activeIndex]} alt="Active" />
                </div>
                {imageCount > 2 && (
                    <div className="slider-element slider-next">
                        <img src={pathImage + images[nextImgIndex]} alt="Next" />
                    </div>
                )}
            </div>
            {imageCount > 1 && (
                <div>
                    <div className="slider-arrows">
                        <label
                            className="slider-arrow-left"
                            onClick={() =>
                                setActiveIndex((current) =>
                                    current === 0 ? imageCount - 1 : current - 1
                                )
                            }
                        >
                            &#5176;
                        </label>
                        <label
                            className="slider-arrow-right"
                            onClick={() =>
                                setActiveIndex((current) =>
                                    current === imageCount - 1 ? 0 : current + 1
                                )
                            }
                        >
                            &#5171;
                        </label>
                    </div>
                    <div className="slider-dots">
                        {images.map((_, index) => (
                            <a
                                key={'slider_link_' + index}
                                onClick={() => setActiveIndex(index)}
                            >
                                <label className={activeIndex === index ? 'slider-dot-active' : 'slider-dot'}></label>
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Slider;