import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Suggestions from './elements/Suggestions';
import Topside from './elements/Topside';
import Actions from '../../redux/actions';

const Home = () => {
    const selectedCity = useSelector(state => state.Content.city);
    const organizations = useSelector(state => state.AreaWork.organizations);
    const dispatch = useDispatch();

    if (selectedCity?.guid && organizations?.length === 0) {
        dispatch(Actions.ActionsForServer.getCityOrganizations(selectedCity.guid, 6, 1, 1));
    }
    const cityInfo = selectedCity || '';

    return (
        <div className="body-container">
            <div className="container">
                <Topside cityInfo={cityInfo} />
                <div className="grid-container">
                    {Array.isArray(organizations) && organizations.map((item, index) => {
                        if (!cityInfo) {
                            return null;
                        }

                        return (
                            <div className="card-shop" key={'shop_' + index}>
                                <Link to={`/${cityInfo.transliteration}/${item.transliteration}/menu/page/1`} key={index}>
                                    <div className="shop-working-status">
                                        {item.workingHours.map((item_2, index_2) => {
                                            if (index_2 === new Date().getDay() - 1) {
                                                const startTime = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${item_2.from}:00`).getTime();
                                                const endTime = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${item_2.to}:00`).getTime();
                                                const currentTime = new Date().getTime();

                                                if (startTime <= currentTime && currentTime <= endTime) {
                                                    return <label key={'working_hours' + index_2}>открыто</label>;
                                                } else {
                                                    if (currentTime < startTime) {
                                                        if ((startTime - currentTime) / 60000 < 30) {
                                                            return <label key={'working_hours' + index_2}>{Math.round((startTime - currentTime) / 60000)} мин<br />до открытия</label>;
                                                        } else {
                                                            return <label key={'working_hours' + index_2}>закрыто<br />до {item_2.from}</label>;
                                                        }
                                                    } else {
                                                        return <label key={'working_hours' + index_2}>закрыто<br />до {item_2.from}</label>;
                                                    }
                                                }
                                            }
                                            return null;
                                        })}
                                    </div>
                                    <div className="card-shop-info">
                                        <div className="shop-image">
                                            <img src={`/uploads/images/events/${item.logo}`} alt={item.name} />
                                        </div>
                                        <div className="card-shop-title">{item.name}</div>
                                        <label className="text-muted">
                                            бесплатная доставка<br />от <span> {item.deliveryParameters.freeDeliverSum} руб</span>
                                        </label>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
                <br /><br />
                <Suggestions />
            </div>
            <br /><br />
        </div>
    );
}

export default Home;