import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import Actions from '../redux/actions'

const TabContent = ({ content }) => (
    <div className="tab-content">{content}</div>
)

function TabsDelivery ({ items }) {
    const [ active, setActive ] = useState(0)
    const openTab = event => setActive(+event.target.dataset.index)
    const dispatch = useDispatch()

    return (
        <div className="tabs">
            <div className="tabs-title"
                onClick={() => {
                    dispatch(Actions.ActionsFor1C.setOrderDateTime('', 0, 0))
                }}>
                {items.map((item, index) => (
                    <button className={`btn btn-light  ${index === active ? 'active' : ''}`} onClick={openTab}
                        data-index={index} key={index}>{item.title}</button>
                ))}
            </div>
            {items[active] && <TabContent {...items[active]} />}
        </div>
    )
}
export default TabsDelivery