import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Actions from '../../redux/actions';
import Images from '../../assets/images';
import SelectCity from './elements/SelectCity';
import ModalSelectCity from './ModalSelectCity';
import Sidebar from './elements/Sidebar';

const Header = () => {
    const { cityName } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedCity = useSelector(state => state.Content.city);
    const carts = useSelector(state => state.AreaWork.carts);
    const cartsTotalPrice = useSelector(state => state.AreaWork.cartsTotalPrice);

    const [isLoadingCity, setIsLoadingCity] = useState(true);

    useEffect(() => {
        if (cityName && (!selectedCity || selectedCity.transliteration !== cityName)) {
            setIsLoadingCity(true);
            dispatch(Actions.ActionsCity.fetchCityByName(cityName, 6, 0, 1, null))
                .finally(() => setIsLoadingCity(false));
        } else {
            setIsLoadingCity(false);
        }
    }, [cityName, dispatch]);

    useEffect(() => {
        if (selectedCity && selectedCity.guid) {
            const inCart = location.pathname.includes('/cart');
            if (!inCart && carts?.length === 0) {
                dispatch(Actions.ActionsFor1C.getCartsFromServer(selectedCity.guid));
            }
        }
    }, [selectedCity, location.pathname, dispatch]);

    if (isLoadingCity) {
        return null;
    }

    return (
        <div className="header-container">
            {!selectedCity.guid && <ModalSelectCity hide={() => true} />}
            <div className="container">
                <nav className="navbar navbar-expand-md navbar-light">
                    <Sidebar cityInfo={selectedCity} />
                    <Link to={selectedCity ? `/${selectedCity.transliteration}/home` : '/'} className="header-brand-image">
                        <img src={Images.MainLogo} alt="Main Logo" style={{ transform: 'rotate(270deg)' }} />
                    </Link>
                    <div className="d-sm-none d-md-block d-none d-sm-block">
                        <SelectCity />
                    </div>
                    <Link to={selectedCity ? `/${selectedCity.transliteration}/cart` : '/cart'}>
                        <img className="header-cart-image" src={Images.IconBuy} alt="Cart Icon" />
                        <label className="header-total-price">{cartsTotalPrice} &#8381;</label>
                    </Link>
                </nav>
            </div>
        </div>
    );
};

export default React.memo(Header);