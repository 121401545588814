import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Actions from '../../../redux/actions'
import Images from '../../../assets/images'

class ProductsSearch extends Component {
    render(){
        return (
            <Fragment>
                <img className="icon-search" src={Images.IconSearch} />
                <input autoComplete={'off'} className="input-search form-control" placeholder='Поиск'
                    onBlur={(event) => {
                        if (event.target.value?.length > 0) {
                            this.props.getOrganizationProducts(this.props.organizationInfo.guid, null, null, event.target.value, 4, 0)
                            this.props.parentCallback(event.target.value)
                        }
                    }}
                />
            </Fragment>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    organizationProducts: state.AreaWork.organizationProducts,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationProducts: (data_1, data_2, data_3, data_4, data_5, data_6) => {
            dispatch(Actions.ActionsForServer.getOrganizationProducts(data_1, data_2, data_3, data_4, data_5, data_6))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductsSearch)