import React, { Component } from 'react'
import Images from '../assets/images'

class NotFound extends Component {
    render () {
        return (
            <div className="body-container">
                <div className="container">
                    <div className="not-found-container">
                        <div className="not-found-image"><img src={Images.E404} /></div>
                        <label>Страница не найдена!</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound