import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Images from '../../assets/images'
import Actions from '../../redux/actions'

function GetCities({ hide }) {
    const cities = useSelector(state => state.Content.cities)
    const dispatch = useDispatch()

    return (
        cities !== null && cities.map((item, index) => (
            <li key={'li_' + index}>
                <Link
                    to={'/' + item.transliteration + '/home'}
                    className="text-dark"
                    onClick={() => {
                        dispatch(Actions.ActionsCity.setSelectedCity(item))
                        dispatch(Actions.ActionsForServer.getCityOrganizations(item.guid, 6, 1, 1))
                        hide()
                    }}
                >
                    {item.name}
                </Link>
            </li>
        ))
    )
}

export default function ModalSelectCity({ hide }) {
    const cities = useSelector(state => state.Content.cities)
    const selectedCity = useSelector(state => state.Content.city)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(Actions.ActionsCity.fetchCities())
    }, [dispatch])

    return (
        <div className="modal-container">
            <div className="modal-container-city">
                <div className="row">
                    <div className="city-image"><img src={Images.IconLocation} alt="City Icon" /></div>
                    <label className="col">
                        {selectedCity ? selectedCity.name : 'Выбор города'}
                    </label>
                    <div className="col-1">
                        <button className="btn btn-light" onClick={() => hide()}>&#10006;</button>
                    </div>
                </div><br />
                <ul>
                    <GetCities hide={hide} />
                </ul>
            </div>
        </div>
    )
}