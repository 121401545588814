import React, { Component } from 'react'

class Information extends Component {
    render(){
        return (
            <div>
                {this.props.organizationInfo ? this.props.organizationInfo.description : null}
            </div>
        )
    }
}

export default Information