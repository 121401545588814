import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Actions from '../../redux/actions';
import Images from '../../assets/images';
import SelectCity from './elements/SelectCity';

function GetCities({ hide }) {
    const dispatch = useDispatch();

    const selectedCity = useSelector((state) => state.Content.city, shallowEqual);

    return (
        selectedCity && (
            <div>
                <Link
                    to={`/${selectedCity.transliteration}/shops/page/1`}
                    className="text-dark"
                >
                    <div
                        className="city-categories-list"
                        onClick={hide}
                    >
                        <img src={Images.HomeBasket} alt="Home Basket" />
                        <label>Просмотреть все</label>
                    </div>
                </Link>
                {selectedCity.categories.map((item, index) => (
                    item && item?.length > 0 && (
                        <Link
                            to={`/${selectedCity.transliteration}/shops/${item[0].guid}/page/1`}
                            key={'link_' + index}
                            className="text-dark"
                            onClick={() => {
                                dispatch(Actions.ActionsForServer.getCityOrganizations(selectedCity.guid, 6, 1, 0, item[0].guid));
                                hide();
                            }}
                        >
                            <div className="city-categories-list">
                                <img
                                    src={`/uploads/images/events/${item[0].image}`}
                                    alt={item[0].name}
                                />
                                <label>{item[0].name}</label>
                            </div>
                        </Link>
                    )
                ))}
                <br /><br />
                <div className="d-md-none">
                    <SelectCity />
                </div>
            </div>
        )
    );
}

export default function ModalSidebar(props) {
    return (
        <div className="modal-container">
            <div className="modal-sidebar">
                <button className="btn btn-light" onClick={props.hide}>
                    &#10006;
                </button>
                <br /><br />
                <GetCities hide={props.hide} />
            </div>
        </div>
    );
}