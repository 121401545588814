import React, { Component } from 'react'

class ApiInfo extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="body-container">
                <div className="container">
                    <h1>Формат обмена данными - JSON</h1>
                    <h1>Данные, которые можно получить из БД</h1>
                    <b>1. Список городов</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/all-cities<br/><br/>

                    <b>2. Список организаций по городу</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/organizations-by-city<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ cityGuid (guid города) и его значение<br/>
                    - ключ random со значением 1<br/>
                    - ключ limit и его значение (любое положительное число, количество записей из выборки)<br/><br/>

                    <b>3. Данные организации по guid</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/organization-by-guid<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ guid и его значение<br/><br/>

                    <b>4. Список улиц для доставки по городу (живой поиск)</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/address-by-name<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ cityGuid и его значение<br/>
                    - ключ name и его значение<br/><br/>

                    <s><b>5. Адреса пунктов самовывоза по организации</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/pickup-points-by-organization<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ cityGuid и его значение<br/>
                    - ключ organizationGuid и его значение<br/><br/></s>

                    <b>6. Поиск продуктов организации по названию</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/products-by-name<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ organizationGuid и его значение<br/>
                    - ключ searchValue и его значение<br/><br/>

                    <b>7. Список продуктов по организации или категории</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/products-by-organization<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ organizationGuid (guid организации) и его значение<br/>
                    - ключ categoryGuid и его значение, если требуется произвести выборку товаров по категории, если же не требуется - значение равно null<br/>
                    - ключ parentCategoryGuid и его значение<br/>
                    - ключ productValue и его значение<br/>
                    - ключ limit и его значение (любое положительно число, количество записей из выборки)<br/>
                    - ключ page и его значение (при первичном запросе значение равно 0, номер страницы результата выборки)<br/><br/>

                    <b>8. Данные продукта по guid</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/product-by-guid<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ guid и его значение<br/><br/>

                    <s><b>9. Список спецификаций для продуктов</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/specifications-by-product<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ productGuid и его значение<br/><br/></s>

                    <b>10. Получить ссылку для оплаты по СБП</b><br/>
                    Метод запроса POST<br/>
                    <s><b>Адрес</b> http://marketa.su/api/order-qr-link<br/></s>
                    <b>Адрес</b> http://marketa.su/api/sbp-find-link<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ orderGuid и его значение<br/><br/>

                    <b>11. Список категорий товаров по организации</b><br/>
                    Метод запроса POST<br/>
                    <b>Адрес</b> http://marketa.su/api/categories-by-organization<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ organizationGuid и его значение<br/><br/>
                    <hr />

                    <h1>Данные, которые ПРИХОДЯТ/УХОДЯТ из/в 1с<br/></h1>
                    <h3>Пункты с пометкой <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО</label> - запросы, которые необходимо произвести в указанной последовательности для 
                    создания документа с заказом в 1с.<br/>
                    Для всех запросов ниже использоваталь метод POST.</h3><br/><br/>

                    <h3>1.1 Инициализация новой сессии <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №1</label></h3>
                    <b>Адрес</b> http://marketa.su/api/session<br/>
                    <b>Описание</b><br/>
                    Выполнять запрос при первичном посещёнии сайта-клиента.
                    В 1с создается документ "Сессия агрегатора", сайту-клиенту в ответ приходит guid этого документа. 
                    Данный guid нужно хранить на стороне сайта-клиента с ключом sessionId и передавать при выполнении всех 
                    остальных запросов (ниже по списку).<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, sessionId: "8ce8a0e2-3b1a-11ed-850b-0050569df549"}, null, 2)}</pre>
                    <br/>

                    <h3>1.2 Заказ в ресторане (сканирование QR-кода)</h3>
                    <b>Адрес</b> http://marketa.su/api/add-table<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ branchId и его значение (пример "12deafb4-5c5f-11e8-8f7d-00155dd9fd01")<br/>
                    - ключ tableId и его значение (пример "3bdffbc0-2b2b-11ea-a9a5-86b1f8341741")<br/>
                    <b>Описание</b><br/>
                    Необходимо выполнять сразу после создания сессии в случае, если заказ оформляется в ресторане через QR-код.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, message: 'Table added to cart'}, null, 2)}</pre>
                    <br/>

                    <h3>2. Добавить/удалить товар в корзину <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №2</label></h3>
                    <b>Адрес</b> http://marketa.su/api/add-to-cart<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ organizationId и его значение (пример "12deafb4-5c5f-11e8-8f7d-00155dd9fd01")<br/>
                    - ключ productId и его значение (пример "1c8ae67a-0bdb-11ec-850a-0050569dbef0")<br/>
                    - ключ productCount и его значение (тип int, пример 1 или -1, указанное количество товара будет добавлено/удалено в корзину 
                    в зависимости от значения)<br/>
                    - ключ additions со значением в виде массива объектов (при удалении товара или отсутствии спецификаций - передать пустой массив [])<br/>
                    - - ключ productId с его значением (тип string)<br/>
                    - - productCount с его значением (тип int)<br/>
                    <b>Описание</b><br/>
                    Выполнять запрос каждый раз при добавлении товара и при изменении количества товара на странице корзины.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true}, null, 2)}</pre>
                    <br/>

                    <h3>3. Передать данные о пользователе <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №3</label></h3>
                    <b>Адрес</b> http://marketa.su/api/user-check<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ phone и его значение (номер телефона, пример 9876665544)<br/>

                    Если пользователь не авторизован, то ещё добавить:<br/>
                    - ключ name и его значение (тип string)<br/>
                    - ключ birthday и его значение (дата в unix формате, пример 1663924978)<br/>

                    <b>Описание</b><br/>
                    На данном этапе формируется связь между документами "Клиент" и "Заказ" в 1с. Если пользователь не найден в базе, 
                    то происходит авторегистрация. Дату рождения (для ключа birthday) на стороне сайта-клиента разрешено заполнять неавторизованному 
                    пользователю только один раз за текущую сессию, далее поле не должно быть доступно для редактирования. 
                    Если пользователь авторизован, то данные пользователя можно получить по запросу user-data (пользователю на стороне сайта-клиента разрешено 
                    изменять только имя и пол). effectOfChanges:success в ответе говорит о том, что текущая и предыдущие сессии были объединены. <br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, data: {
                            sessionId: "8ce8a0e2-3b1a-11ed-850b-0050569df549",
                            effectOfChanges: "success"
                        }}, null, 2)}</pre>
                    <br/>

                    <h3>4. Передать адрес доставки или пункта самовывоза <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №4</label></h3>
                    <b>Адрес</b> http://marketa.su/api/order-address<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ cityId и его значение (пример "8b72e116-938b-11e7-ac80-00155dd9fd00")<br/>
                    - ключ addressId и его значение (ключ addressId из БД, тип int, пример 7001700002100449543979008)<br/>
                    - ключ houseNumber и его значение (номер дома, тип string)<br/>
                    - ключ pickup со значением 0 или 1 (если выбран способ доставки "Самовывоз", то передать 1, иначе - 0)<br/>
                    - ключ organizations со значением  в виде массива (пример ["12deafb4-5c5f-11e8-8f7d-00155dd9fd01", "66674154-2b2b-11ea-a9a5-86b1f8341741"])<br/>
                    <b>Описание</b><br/>
                    Запрос может быть выполнен только после успешного ответа на запрос user-check (пункт 3).<br/>
                    <b>Описание ответа:</b><br/>
                    delivering - при 1 доставка осуществляется, при 0 - не осуществляется;<br/>
                    minimumAmountOfOrder - минимальная сумма заказа (если минимальная сумма для заказа не набрана, 
                        то нужно об этом уведомить пользователя);<br/>
                    deliveryPrice - цена доставки;<br/>
                    freeDeliverSum - сумма, от которой доставка будет бесплатной.<br/>
                    <b>Пример успешного ответа (при выборе доставки заказа курьером)</b><br/>
                    <pre>{JSON.stringify({
                        success: true,
                        data: [
                            {
                                organizationId: "12deafb4-5c5f-11e8-8f7d-00155dd9fd01",
                                delivering: 1,
                                minimumAmountOfOrder: 500,
                                deliveryPrice: 130,
                                freeDeliverSum: 700
                            }
                        ]
                    }, null, 2)}</pre>
                    <br/>

                    <h3>5. Получить временные интервалы доставки</h3>
                    <b>Адрес</b> http://marketa.su/api/periods-time<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ cityId и его значение (пример "8b72e116-938b-11e7-ac80-00155dd9fd00")<br/>
                    - ключ date и его значение (дата в unix формате, пример 1663924978)<br/>
                    - ключ organizationId и его значение (пример "12deafb4-5c5f-11e8-8f7d-00155dd9fd01")<br/>
                    <b>Описание</b><br/>
                    Запрос выполнять только после выбора даты доставки пользоваталем.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({
                        success: true,
                        data: [
                            {
                            periodId: "53d92f55-e696-11e1-9c69-bcaec58caea2",
                            view: "13:10 - 13:25"
                            },
                            {
                            periodId: "53d92f56-e696-11e1-9c69-bcaec58caea2",
                            view: "13:20 - 13:35"
                            },
                            {
                            periodId: "53d92f57-e696-11e1-9c69-bcaec58caea2",
                            view: "13:30 - 13:45"
                            },
                            {
                            periodId: "53d92f58-e696-11e1-9c69-bcaec58caea2",
                            view: "13:40 - 13:55"
                            }
                        ]
                        }, null, 2)}
                    </pre>
                    <br/>

                    <h3>6. Передать дату и время доставки <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №5</label></h3>
                    <b>Адрес</b> http://marketa.su/api/order-datetime<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ cityId и его значение (пример "8b72e116-938b-11e7-ac80-00155dd9fd00")<br/>
                    - ключ organizationId и его значение (пример "12deafb4-5c5f-11e8-8f7d-00155dd9fd01")<br/>
                    - ключ deliveryDate и его значение (дата доставки/самовывоза в unix формате, пример 1663924978)<br/>
                    - ключ deliveryTime и его значение (интервал времени доставки/самовывоза - это periodId из запроса periods-time, пример "53d92f55-e696-11e1-9c69-bcaec58caea2")<br/>
                    <b>Описание</b><br/>
                    Запрос выполнять только после того, как пользователь заполнил все основные данные (телефон, адрес доставки/самовывоза, дату и время)<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, message: "Order can be placed"}, null, 2)}</pre>
                    <br/>

                    <h3>7. Получить пересчитанную итоговую сумму заказа <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №6</label></h3>
                    <b>Адрес</b> http://marketa.su/api/order-audit<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ cityId и его значение (пример "8b72e116-938b-11e7-ac80-00155dd9fd00")<br/>
                    - ключ organizations со значением в виде массива значений guid организаций (пример 
                    ["12deafb4-5c5f-11e8-8f7d-00155dd9fd01", "66674154-2b2b-11ea-a9a5-86b1f8341741"])<br/>
                    - ключ paymentType со значением 1 (наличка), 2 (безнал), 3 (онлайн) или 4 (через СБП, для моб.приложения доступен только данный вид оплаты)<br/>
                    - ключ quantityOfPeople и его значение (тип int, количество человек/приборов)<br/>
                    - ключ soonAsPossible со значением 0 или 1 (тип int, отметка "можно раньше")<br/>
                    - ключ entrance и его значение (тип string, подъезд)<br/>
                    - ключ floor и его значение (тип string, этаж)<br/>
                    - ключ apartment и его значение (тип string, квартира)<br/>
                    - ключ comment и его значение (тип string, комментарий к заказу)<br/>
                    <b>Описание</b><br/>
                    Запрос выполнять только после успешного ответа на запрос order-datetime. При успешном ответе можно оформлять заказ запросом order-done (если 
                    пользователь авторизован) или отправить СМС запросом send-sms (если пользователь не авторизован).<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, message: "Order checked"}, null, 2)}</pre>
                    <br/>

                    <h3>8. Передать запрос о формировании заказазов <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №7</label></h3>
                    <b>Адрес</b> http://marketa.su/api/order-done<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ cityId и его значение (пример "8b72e116-938b-11e7-ac80-00155dd9fd00")<br/>
                    - ключ organizations со значением в виде массива значений guid организаций (пример 
                    ["12deafb4-5c5f-11e8-8f7d-00155dd9fd01", "66674154-2b2b-11ea-a9a5-86b1f8341741"])<br/>
                    - ключ paymentType со значением 1 (наличка), 2 (безнал), 3 (онлайн) или 4 (через СБП, для моб.приложения доступен только данный вид оплаты)<br/>
                    - ключ quantityOfPeople и его значение (тип int, количество человек/приборов)<br/>
                    - ключ soonAsPossible со значением 0 или 1 (тип int, отметка "можно раньше")<br/>
                    - ключ entrance и его значение (тип string, подъезд)<br/>
                    - ключ floor и его значение (тип string, этаж)<br/>
                    - ключ apartment и его значение (тип string, квартира)<br/>
                    - ключ comment и его значение (тип string, комментарий к заказу)<br/>
                    <b>Описание</b><br/>
                    Запрос формирует заказы в 1с. Выполнять запрос только после успешной проверки заказов запросом order-audit. 
                    Если пользователь не авторизован, то сперва необходимо выполнить запросы send-sms и valid-code.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({
                        success: true,
                        data: [
                            {
                            organizationId: "12deafb4-5c5f-11e8-8f7d-00155dd9fd01",
                            orderId: "212241ea-3efe-11ed-850b-0050569df549"
                            }
                        ]
                        }, null, 2)}</pre>
                    <br/>

                    <s><h3>9.1 Передать информацию об онлайн-оплате <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №8 (ПРИ ОНЛАЙН-ОПЛАТЕ, НЕАКТУЛЬНО ДЛЯ МОБ.ПРИЛОЖЕНИЯ)</label></h3>
                    <b>Адрес</b> http://marketa.su/api/order-paid-online<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ orderId и его значение<br/>
                    - ключ sberId и его значение<br/>
                    <b>Описание</b><br/>
                    ...<br/>
                    <b>Пример успешного ответа</b><br/><div>
                    </div><br/></s>

                    <h3>9.2 Оплата через СБП <label style = {{ color: 'red' }}>ОБЯЗАТЕЛЬНО №8 (ПРИ ВЫБОРЕ ОПЛАТЫ ПО СБП)</label></h3>
                    <s><b>Адрес</b> http://marketa.su/api/order-paid-qr<br/></s>
                    <b>Адрес</b> http://marketa.su/api/sbp-create<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ orderId и его значение (тип string, пример "567d6baa-3fb4-11ed-850b-0050569df549", orderId из ответа на запрос order-done)<br/>
                    - ключ paymentGateway и значение payment.entrega.su или akarapayment.entrega.su (информация о платежном шлюзе есть в ответе на запрос organization-by-guid)<br/>
                    - ключ orderNumber и его значение (тип string, пример "SCH000005550", orderNumber из ответа на запрос order-done)<br/>
                    - ключ orderSum и его значение (сумма заказа, payableSum из ответа на запрос order-done)<br/>
                    - ключ type со значением 0 или 1 (0 для сайта-клиента, 1 для моб.приложения)<br/>
                    <b>Описание</b><br/>
                    Вызывать запрос ТОЛЬКО 1 раз после формирования заказа (запрос order-done). В ответе содежится ссылка на оплату заказа.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify(
                        {
                            success: true,
                            url: "https://qr.nspk.ru/AD10002LFDT1E1LT9RVBBT2LURT5QNM8?type=02&bank=100000000111&sum=98000&cur=RUB&crc=9ADC"
                        }, null, 2)}</pre>
                    <br/>

                    <h3>10. Отправить СМС с кодом подтверждения </h3>
                    <b>Адрес</b> http://marketa.su/api/send-sms<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ phone с его значением (10 цифр номера телефона, без +7 или 8, тип int)<br/>
                    - ключ ipAddress с его значением (ip адрес пользователя, необязательный параметр)<br/>
                    <b>Описание</b><br/>
                    Выполнять после запроса order-audit только в случае, если  пользователь не авторизован.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, message: "Verification code sent"}, null, 2)}</pre>
                    <br/>

                    <h3>11. Валидация кода подтверждения из СМС</h3>
                    <b>Адрес</b> http://marketa.su/api/valid-code<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ code с его значением (тип int)<br/>
                    - ключ type со значением 1 (при формировании заказа, если пользователь неавторизован), 2 (при авторизации) или 3 (при регистрации)<br/>
                    <b>Описание</b><br/>
                    Проверка введеного пользователем кода подтверждения из СМС.<br/>
                    <b>Пример успешного ответа при авторизации</b><br/>
                    <pre>{JSON.stringify({success: true, message: "Currently in use"}, null, 2)}</pre>
                    <br/>

                    <h3>12. Авторизация</h3>
                    <b>Адрес</b> http://marketa.su/api/user-auth<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ phone и его значение (номер телефона, пример 9876665544)<br/>
                    - ключ getSession со значением 0 или 1 (0 - если корзина не пустая, 1 - если корзина пустая)<br/>
                    <b>Описание</b><br/>
                    Запрос проверяет существование пользователя в базе по номеру телефона. Если пользователь найден, то будет отправлено СМС, 
                    соответственно, необходимо показать пользователю поле для ввода кода подтверждения из СМС, а после ввода кода вызвать valid-code.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true,  message: "Verification code sent"}, null, 2)}</pre>
                    <br/>

                    <h3>13. Регистрация</h3>
                    <b>Адрес</b> http://marketa.su/api/user-create<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ phone и его значение (тип int, номер телефона, пример 9876665544)<br/>
                    - ключ name и его значение (тип string)<br/>
                    - ключ birthday и его значение (дата в unix формате, пример 1663924978, необязательный параметр)<br/>
                    <b>Описание</b><br/>
                    Запрос проверяет существование пользователя в базе по номеру телефона. Если пользователь не найден, то будет отправлено СМС,
                    соответственно, необходимо показать пользователю поле для ввода кода подтверждения из СМС, а после ввода кода вызвать valid-code.
                    Если пользователь будет найден (уже зарегистрирован), то в ответе будет message:"User already exists".<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true, message: "Verification code sent"}, null, 2)}</pre>
                    <br/>

                    <s><h3>14. Редактирование информации о пользователе</h3>
                    <b>Адрес</b> http://marketa.su/api/user-update<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ number и его значение (номер телефона, пример 9876665544)<br/>
                    - ключ name и его значение<br/>
                    - ключ gender и его значение<br/>
                    <b>Описание</b><br/>
                    Пользователь не имеет возможности изменять номер телефона и дату рождения, но есть возможность внести изменения 
                    через операторов колл-центра.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <br/></s>

                    <h3>15. Получить информацию о пользователе по текущей сессии</h3>
                    <b>Адрес</b> http://marketa.su/api/user-data<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    <b>Описание</b><br/>
                    Запрос позволяет получить информацию о пользователе по его текущей сессии, но данные в ответе будут полноценными только в том случае, 
                    если пользователь авторизован или заполнил данные на странице корзины в текущей сессии.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true,
                        data: {
                            name: "Вася",
                            number: 9876665544,
                            birthday: 1664184249,
                            rigistrationDate: 1664184249,
                            gender: "муж"
                        }}, null, 2)}</pre>
                    <br/>

                    <h3>16. Получить данные корзины</h3>
                    <b>Адрес</b> http://marketa.su/api/cart-data<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (тип string, пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ cityId и его значение (пример "8b72e116-938b-11e7-ac80-00155dd9fd00")<br/>
                    - ключ organizations со значением в виде массива значений guid организаций (пример ["8b72e116-938b-11e7-ac80-00155dd9fd00"]), если требуется получить все 
                        сущестующие корзины в пределах города, то передать пустой массив []<br/>
                    - ключ details и его значение (0 - получить тольку сводную информацию по корзине, 1 - получить сводную информацию и все товары корзины)<br/>
                    <b>Описание</b><br/>
                    Получить данные корзины по сесиии.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({
                        success: true,
                        data: [
                            {
                                organizationInfo: {
                                    _id: "6320448bf184d078faebc97e",
                                    guid: "12deafb4-5c5f-11e8-8f7d-00155dd9fd01",
                                    cityGuid: "8b72e116-938b-11e7-ac80-00155dd9fd00",
                                    name: "Mr. tako",
                                    transliteration: "mistertako",
                                    active: true,
                                    branches: [
                                    {
                                        _id: "6320448bf184d078faebc97f",
                                        guid: "12deafb5-5c5f-11e8-8f7d-00155dd9fd01",
                                        name: "ИП Голиков Павел Геннадьевич",
                                        addressId: "7.00000010000796e+24",
                                        street: "Трифонова",
                                        house: "20"
                                    }
                                    ]
                                },
                                cartPrice: 200,
                                deliveryPrice: 10000,
                                discountSum: 0,
                                sum: 10200,
                                productsCount: 1,
                                cart: [
                                    {
                                    productCount: 1,
                                    gift: 0,
                                    additions: [],
                                    productInfo: {
                                        show: true,
                                        active: true,
                                        available: true,
                                        onlyWithSpecifications: false,
                                        groupWith: "",
                                        groupName: "",
                                        groupWeight: "0",
                                        quantity: 100000000,
                                        calories: 99.15,
                                        fat: 3.91,
                                        protein: 7.51,
                                        carbohydrate: 8.48,
                                        weight: "390",
                                        _id: "632040eb27f114773f3ac965",
                                        guid: "1c8ae67a-0bdb-11ec-850a-0050569dbef0",
                                        organizationGuid: "12deafb4-5c5f-11e8-8f7d-00155dd9fd01",
                                        name: "Рамен с курицей \"Терияки\"",
                                        price: "200",
                                        fakeprice: "0",
                                        categoryGuid: "51b90f2b-5d60-11e8-8f7d-00155dd9fd01",
                                        description: "Бульон рамен (куриный бульон, чесночно-имбирная заправка, соевый соус) с пшеничной лапшой, редисом, куриным яйцом и курицей \"Терияки\" (куриное филе обжаренное с кукурузным крахмалом, соевым соусом и соусом терияки).\nБЖУ на 100 г.\nБелки, г — 7,51\nЖиры, г — 3,91\nУглеводы, г — 8,48",
                                        measure: "шт",
                                        image: "1c8ae67b-0bdb-11ec-850a-0050569dbef0.jpg",
                                        imageGrid: "1c8ae67b-0bdb-11ec-850a-0050569dbef0.jpg",
                                        specifications: [],
                                        updated: "1663058155734",
                                        __v: 0
                                    }
                                    }
                                ]
                            }
                        ]
                        }, null, 2)}</pre>
                    <br/>

                    <h3>17. Очистить корзину по организации</h3>
                    <b>Адрес</b> http://marketa.su/api/cart-clear<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ sessionId и его значение (пример "8ce8a0e2-3b1a-11ed-850b-0050569df549")<br/>
                    - ключ organizations со значением в виде массива значений guid организаций (пример 
                    ["12deafb4-5c5f-11e8-8f7d-00155dd9fd01", "66674154-2b2b-11ea-a9a5-86b1f8341741"])<br/>
                    <b>Описание</b><br/>
                    Очистка корзин для организаций из списка.<br/>
                    <b>Описание ответа:</b><br/>
                    effectOfChanges - success (корзина удалена), uncorrect organizationId (организация не найдена в текущей сессии), 
                    cart not found (корзина не найдена не найдена в текущей сессии), error (непредвиденная ошибка).<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true,
                    cartChanges: [
                        {
                            organizationId: "66674154-2b2b-11ea-a9a5-86b1f8341741",
                            effectOfChanges: "cart not found"
                        }
                    ]}, null, 2)}</pre>
                    <br/>

                    <h3>18. Информация по заказу</h3>
                    <b>Адрес</b> http://marketa.su/api/order-info<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ orderId и его значение (пример "57e64971-7483-11ed-850b-0050569df549")<br/>
                    <b>Описание</b><br/>
                    Получение информации по уникальному идентификатору заказа.<br/>
                    <b>Описание ответа:</b><br/>
                    orderNumber – номер заказа;<br/>
                    organizationId – идентификатор организации;<br/>
                    deliveryType – способ получения заказа (deliveryByCourier - доставка, pickupByClient - самовывоз, inRestaurant - заказ на месте).<br/>
                    orderSum – сумма к оплате;<br/>
                    deliveryDate – unix-дата доставки;<br/>
                    status – статус заказа (created - новый, accepted - принят, cooking - на кухне, packed or in delivery - упакован,
                        canceled - отменен, completed - выполнен);<br/>
                    numberOfPersons – количество персон;<br/>
                    paymentMethod – способ оплаты (cash - наличными, card - по карте, online - онлайн, without payment - без оплаты);<br/>
                    possibleEarlier – в случае true заказ можно привезти раньше, иначе нельзя;<br/>
                    deliveryComment – комментарий от сотрудников доставки;<br/>
                    restaurantComment – комментарий от сотрудников ресторана/магазина.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true,
                        order: {
                            orderNumber: "SCH000005622",
                            organizationId: "514236af-95f5-11eb-850a-0050569dbef0",
                            deliveryType: "delivery",
                            address: "Ленина ул, д.2, Санкт-Петербург г",
                            orderSum: 900,
                            deliveryDate: 1670198400,
                            deliveryTime: "14:40 - 14:55",
                            status: "created",
                            numberOfPersons: 1,
                            paymentMethod: "cash",
                            possibleEarlier: true,
                            deliveryComment: "",
                            restaurantComment: ""
                        }
                    }, null, 2)}</pre>
                    <br/>

                    <h3>19. История заказов</h3>
                    <b>Адрес</b> http://marketa.su/api/orders-history<br/>
                    <b>Объекты в body, которые нужно передать в запросе</b><br/>
                    - ключ phone и его значение (номер телефона, пример 9876665544)<br/>
                    <b>Описание</b><br/>
                    История заказов по номеру телефона пользователя.<br/>
                    <b>Описание ответа:</b><br/>
                    orderNumber – номер заказа;<br/>
                    organizationId – идентификатор организации;<br/>
                    deliveryType – способ получения заказа (deliveryByCourier - доставка, pickupByClient - самовывоз, inRestaurant - заказ на месте).<br/>
                    orderSum – сумма к оплате;<br/>
                    deliveryDate – unix-дата доставки;<br/>
                    status – статус заказа (created - новый, accepted - принят, cooking - на кухне, packed or in delivery - упакован,
                        canceled - отменен, completed - выполнен);<br/>
                    numberOfPersons – количество персон;<br/>
                    paymentMethod – способ оплаты (cash - наличными, card - по карте, online - онлайн, without payment - без оплаты);<br/>
                    possibleEarlier – в случае true заказ можно привезти раньше, иначе нельзя;<br/>
                    deliveryComment – комментарий от сотрудников доставки;<br/>
                    restaurantComment – комментарий от сотрудников ресторана/магазина.<br/>
                    <b>Пример успешного ответа</b><br/>
                    <pre>{JSON.stringify({success: true,
                    orders: [
                        {
                            orderId: "57e64971-7483-11ed-850b-0050569df549",
                            orderNumber: "SCH000005622",
                            deliveryType: "deliveryByCourier",
                            orderDate: 1670245101,
                            deliveryDate: 1670198400,
                            deliveryTime: "14:40 - 14:55",
                            organizationId: "514236af-95f5-11eb-850a-0050569dbef0",
                            status: "created",
                            products: [
                                {
                                    "productId": "db12b414-95fc-11eb-850a-0050569dbef0",
                                    "count": 2,
                                    "sum": 900,
                                    "specifications": []
                                }
                            ],
                            payment: {
                                productSum: 900,
                                deliverySum: 0,
                                discountSum: 0,
                                total: 900,
                                paymentType: "cash"
                            }
                        }
                    ]}, null, 2)}</pre>
                    <br/>
                </div>
            </div>
        )
    }
}

export default ApiInfo