import React from 'react';
import { connect } from 'react-redux';
import Actions from '../../../redux/actions';
import { Link } from 'react-router-dom';
import Images from '../../../assets/images';

const Topside = ({ city, getCityOrganizations }) => {
    const cityName = city ? '/' + city.transliteration : null;
    return (
        <div className="home-topside row">
            {cityName && (
                <Link to={cityName + '/shops/page/' + 1}>
                    <img src={Images.HomeBasket} alt="Просмотреть все" /><br />
                    <label>Просмотреть все</label>
                </Link>
            )}
            {city && city.categories && city.categories.map((item, index) => (
                item && item?.length > 0 && (
                    <Link
                        to={cityName + '/shops/' + item[0].guid + '/page/' + 1}
                        key={'link_' + index}
                        onClick={() => {
                            getCityOrganizations(6, 1, 0, item[0].guid);
                        }}
                    >
                        <img src={'/uploads/images/events/' + item[0].image} alt={item[0].name} /><br />
                        <label>{item[0].name}</label>
                    </Link>
                )
            ))}
        </div>
    );
};

const mapStateToProps = (state) => ({
    city: state.Content.city,
});

const mapDispatchToProps = (dispatch) => ({
    getCityOrganizations: (data_1, data_2, data_3, data_4) => {
        dispatch(Actions.getCityOrganizations(data_1, data_2, data_3, data_4));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Topside);