import * as types from '../types';

const initialState = {
    cities: [],
    city: {},
    user: {},
};

function Content(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_USER_LOCAL:
            return {
                ...state,
                user: action.payload,
            };
        case types.CONTENT_FETCH_CITIES:
            return {
                ...state,
                cities: action.payload,
            }
        case types.SET_SELECTED_CITY:
            return {
                ...state,
                city: action.payload,
            }
        default:
            return state;
    }
}

export default Content;