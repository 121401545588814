import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Actions from '../../redux/actions';
import { connect } from 'react-redux';
import Components from '../../components';

class Shops extends Component {
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { city, match } = this.props;
        const { cityCategoryGuid, pageIndex } = match.params;

        if (
            city !== prevProps.city ||
            cityCategoryGuid !== prevProps.match.params.cityCategoryGuid ||
            pageIndex !== prevProps.match.params.pageIndex
        ) {
            this.fetchData();
        }
    }

    fetchData() {
        const { city, organizations, match } = this.props;
        const { cityCategoryGuid, pageIndex } = match.params;

        if (city && city.guid && (!organizations || organizations.length === 0)) {
            this.props.getCityOrganizations(city.guid, 6, pageIndex, 0, cityCategoryGuid);
        }
    }

    render() {
        const cityInfo = this.props.city ? this.props.city : null;

        return (
            <div className="body-container">
                <div className="container">
                    <div className="grid-container">
                        {Array.isArray(this.props.organizations) && this.props.organizations.map((item) => {
                            if (!item || !item.guid) {
                                return null;
                            }
                            return (
                                <div className="card-shop" key={'shop_' + item.guid} onClick={() => { }}>
                                    <Link to={'/' + cityInfo.transliteration + '/' + item.transliteration + '/menu/page/1'}>
                                        <div className="shop-working-status">
                                            {item.workingHours.map((item_2, index_2) => {
                                                if (index_2 === new Date().getDay() - 1) {
                                                    const startTime = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${item_2.from}:00`).getTime();
                                                    const endTime = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${item_2.to}:00`).getTime();
                                                    const currentTime = new Date().getTime();

                                                    if (startTime <= currentTime && currentTime <= endTime) {
                                                        return <label key={'working_hours' + index_2}>открыто</label>;
                                                    } else if (currentTime < startTime) {
                                                        return (startTime - currentTime) / 60000 < 30
                                                            ? <label key={'working_hours' + index_2}>{Math.round((startTime - currentTime) / 60000)} мин<br />до открытия</label>
                                                            : <label key={'working_hours' + index_2}>закрыто<br />до {item_2.from}</label>;
                                                    } else {
                                                        return <label key={'working_hours' + index_2}>закрыто<br />до {item_2.from}</label>;
                                                    }
                                                }
                                                return null;
                                            })}
                                        </div>
                                        <div className="card-shop-info">
                                            <div className="shop-image">
                                                <img src={'/uploads/images/events/' + item.logo} alt={item.name} />
                                            </div>
                                            <div className="card-shop-title">{item.name}</div>
                                            <label className="text-muted">
                                                бесплатная доставка<br />
                                                от <span>{item.deliveryParameters.freeDeliverSum} руб</span>
                                            </label>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    {this.props.organizationsQuantity > 6 &&
                        <Components.Pagination
                            totalRecords={this.props.organizationsQuantity}
                            pageLimit={6}
                            pageIndex={this.props.match.params.pageIndex}
                            cityInfo={cityInfo}
                            cityCategoryGuid={this.props.match.params.cityCategoryGuid}
                        />
                    }
                </div><br /><br />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    city: state.Content.city,
    organizations: state.AreaWork.organizations,
    organizationsQuantity: state.AreaWork.organizationsQuantity,
});

const mapDispatchToProps = (dispatch) => ({
    getCityOrganizations: (cityGuid, limit, page, random, categoryGuid) =>
        dispatch(Actions.ActionsForServer.getCityOrganizations(cityGuid, limit, page, random, categoryGuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shops);