import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Components from '../components';
import Pages from '../pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/style.css';
import * as api from '../api';
import { setCookie, getCookie } from '../utils/cookies';

class App extends Component {
    async componentDidMount() {
        const token = getCookie('token');
        const tokenExpired = token && await this.isTokenExpired(token);

        if (!token || tokenExpired) {
            await this.fetchToken();
        } else {
            await this.checkSession();
        }
    }

    // Проверка истечения срока действия токена
    async isTokenExpired(token) {
        try {
            const response = await api.checkToken(token);
            return !response.data.success;
        } catch (error) {
            console.error('Error isTokenExpired:', error);
            return true;
        }
    }

    // Метод для получения токена
    async fetchToken() {
        try {
            const response = await api.getToken();
            if (response.data.success && response.data.token) {
                setCookie('token', response.data.token);
                await this.checkSession();
            } else {
                console.error('Unable to fetch token');
            }
        } catch (error) {
            console.error('Error fetchToken:', error);
        }
    }

    // Метод для проверки и обновления сессии
    async checkSession() {
        try {
            const sessionGuid = getCookie('session');
            if (!sessionGuid) {
                const response = await api.getSession();
                if (response.data.success) {
                    setCookie('session', response.data.sessionId, 1);
                } else {
                    console.error('No sessionId received from server');
                }
            }
        } catch (error) {
            console.error('Error checkSession:', error);
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Home {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/home' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Home {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/cart' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Cart {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/success' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Success {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/shops/page/:pageIndex' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shops {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/shops/:cityCategoryGuid/page/:pageIndex' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shops {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/:shopName/:navbarName/page/:pageIndex/:productId?' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shop {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/:shopName/:navbarName/:categoryGuid/page/:pageIndex/:productId?' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shop {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/:shopName/:navbarName/:parentCategoryGuid/page/:pageIndex/:productId?' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shop {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/:shopName/:navbarName/search/:productValue/page/:pageIndex/:productId?' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shop {...props} />
                        </>
                    )} />
                    <Route exact path='/:cityName/:shopName/:navbarName' render={(props) => (
                        <>
                            <Components.Header />
                            <Pages.Shop {...props} />
                        </>
                    )} />
                    <Route exact path='/api-info' component={Pages.ApiInfo}/>
                    <Route exact path='/not-found' component={Pages.NotFound} />
                    <Redirect exact from={'*'} to={'/not-found'} />
                </Switch>
                <Components.Footer/>
            </BrowserRouter>
        );
    }
}

export default App;