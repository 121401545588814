export const SET_USER_LOCAL = 'SET_USER_LOCAL'
export const CONTENT_FETCH_CITIES = 'CONTENT_FETCH_CITIES'
export const SET_SELECTED_CITY = 'SET_SELECTED_CITY';
export const RESET_SELECTED_CITY = 'RESET_SELECTED_CITY';

export const AREA_CITY_ORGANIZATIONS = 'AREA_CITY_ORGANIZATIONS'
export const AREA_CITY_ORGANIZATION = 'AREA_CITY_ORGANIZATION'
export const AREA_CITY_ORGANIZATIONS_QUANTITY= 'AREA_CITY_ORGANIZATIONS_QUANTITY'
export const AREA_CITY_ORGANIZATION_CONTENTS = 'AREA_CITY_ORGANIZATION_CONTENTS'
export const AREA_CITY_ORGANIZATION_CATEGORIES = 'AREA_CITY_ORGANIZATION_CATEGORIES'
export const AREA_CITY_ORGANIZATION_PRODUCTS = 'AREA_CITY_ORGANIZATION_PRODUCTS'
export const AREA_CITY_CATEGORY_PRODUCTS = 'AREA_CITY_CATEGORY_PRODUCTS'
export const AREA_CITY_ORGANIZATION_PRODUCTS_QUANTITY = 'AREA_CITY_ORGANIZATION_PRODUCTS_QUANTITY'
export const AREA_CITY_ORGANIZATION_PRODUCT = 'AREA_CITY_ORGANIZATION_PRODUCT'
export const AREA_CITY_ORGANIZATION_MENU_INSIDE_SEARCH = 'AREA_CITY_ORGANIZATION_MENU_INSIDE_SEARCH'
export const AREA_CITY_ORGANIZATION_MENU_INSIDE_SEARCH_SORTING_MODE = 'AREA_CITY_ORGANIZATION_MENU_INSIDE_SEARCH_SORTING_MODE'

export const CHECKOUT_USER_ERROR = 'CHECKOUT_USER_ERROR'
export const CHECKOUT_ADDRESS_ERROR = 'CHECKOUT_ADDRESS_ERROR'
export const CHECKOUT_DATE_ERROR = 'CHECKOUT_DATE_ERROR'
export const CHECKOUT_TIME_ERROR = 'CHECKOUT_TIME_ERROR'
export const CHECKOUT_PERMISSION_SEND_SMS = 'CHECKOUT_PERMISSION_SEND_SMS'
export const CHECKOUT_STATUS_SEND_SMS = 'CHECKOUT_STATUS_SEND_SMS'
export const CHECKOUT_VALID_CODE = 'CHECKOUT_VALID_CODE'
export const CARTS = 'CARTS'
export const CARTS_TOTAL_PRICE = 'CARTS_TOTAL_PRICE'
export const CART_SET_DELIVERY_PERIODS_TIME = 'CART_SET_DELIVERY_PERIODS_TIME'
export const CART_SET_DELIVERY_STREETS_BY_MASK = 'CART_SET_DELIVERY_STREETS_BY_MASK'