import React from 'react'
import Images from '../assets/images'

export default function Footer() {
    return (
        <div className="footer-container">
            <div className="container">
                <div className="footer-info">
                    <div className="footer-image"><img src={Images.MainLogo} style={{ transform: 'rotate(270deg)' }}/></div>
                    <a href="tel:+79528089476"><img src={Images.IconCall} /></a>
                </div>
            </div>
        </div>
    )
}