import * as types from '../types';
import * as api from '../../api';
import Actions from './';
import { setCookie } from '../../utils/cookies';

const getCityOrganizations = (cityGuid, limit, page, random, categoryGuid) => async (dispatch) => {
    try {
        const response = await api.organizationsByCity(cityGuid, limit, page - 1, random, categoryGuid);
        dispatch({ type: types.AREA_CITY_ORGANIZATIONS_QUANTITY, payload: response.data.dataQuantity });
        dispatch({ type: types.AREA_CITY_ORGANIZATIONS, payload: response.data.data });
    } catch (error) {
        console.error('Error getCityOrganizations:', error);
    }
};

const getCityOrganization = (data_1, data_2, data_3, data_4, data_5) => async (dispatch) => {
    try {
        const response = await api.organizationByName(data_1);
        dispatch({ type: types.AREA_CITY_ORGANIZATION, payload: response.data.data });
        dispatch(Actions.ActionsForServer.getOrganizationContent(response.data.data.guid));
        dispatch(Actions.ActionsForServer.getOrganizationCategories(response.data.data.guid));
        dispatch(Actions.ActionsForServer.getOrganizationProducts(response.data.data.guid, data_2, data_3, data_4, 4, data_5 - 1));
    } catch (error) {
        console.error('Error getCityOrganization:', error);
    }
};

const getSearchProductsName = (data_1, data_2, data_3, data_4) => async (dispatch) => {
    try {
        // Uncomment and use if needed
        // const response = await api.productsByName({
        //     searchValue: data_1,
        //     organizationGuid: data_2,
        //     limit: data_3,
        //     page: data_4,
        // });
        // dispatch({ type: types.AREA_CITY_ORGANIZATION_PRODUCTS_QUANTITY, payload: response.data.dataQuantity });
        // dispatch({ type: types.AREA_CITY_ORGANIZATION_PRODUCTS, payload: response.data.data });
    } catch (error) {
        console.error('Error getSearchProductsName:', error);
    }
};

const getOrganizationContent = (data) => async (dispatch) => {
    try {
        const response = await api.contentByOrganization(data);
        dispatch({ type: types.AREA_CITY_ORGANIZATION_CONTENTS, payload: response.data.data });
    } catch (error) {
        console.error('Error getOrganizationContent:', error);
    }
};

const getOrganizationCategories = (data) => async (dispatch) => {
    try {
        const response = await api.categoriesByOrganization(data);
        dispatch({ type: types.AREA_CITY_ORGANIZATION_CATEGORIES, payload: response.data.data });
    } catch (error) {
        console.error('Error getOrganizationCategories:', error);
    }
};

const getOrganizationProducts = (data_1, data_2, data_3, data_4, data_5, data_6) => async (dispatch) => {
    try {
        const response = await api.productsByOrganization(data_1, data_2, data_3, data_4, data_5, data_6);
        dispatch({ type: types.AREA_CITY_ORGANIZATION_PRODUCTS_QUANTITY, payload: response.data.dataQuantity });
        dispatch({ type: types.AREA_CITY_ORGANIZATION_PRODUCTS, payload: response.data.data });
    } catch (error) {
        console.error('Error getOrganizationProducts:', error);
    }
};

const getOrganizationProduct = (data) => async (dispatch) => {
    try {
        const response = await api.productByGuid(data);
        dispatch({ type: types.AREA_CITY_ORGANIZATION_PRODUCT, payload: response.data.data });
    } catch (error) {
        console.error('Error getOrganizationProduct:', error);
    }
};

const getStreetsByMask = (data) => async (dispatch, getState) => {
    const state = getState();
    const citySelected = state.Content.city;
    if (data?.length >= 2) {
        try {
            const response = await api.streetByName(data, citySelected.guid);
            if (response.data.data?.length > 0) {
                dispatch({ type: types.CART_SET_DELIVERY_STREETS_BY_MASK, payload: response.data.data });
            } else {
                dispatch({ type: types.CART_SET_DELIVERY_STREETS_BY_MASK, payload: [] });
            }
        } catch (error) {
            console.error('Error getStreetsByMask:', error);
            dispatch({ type: types.CART_SET_DELIVERY_STREETS_BY_MASK, payload: [] });
        }
    } else {
        dispatch({ type: types.CART_SET_DELIVERY_STREETS_BY_MASK, payload: [] });
    }
};

const sendSmsCode = (data) => async (dispatch) => {
    dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_VALID_CODE, payload: null });
    dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });
    dispatch({ type: types.CART_SET_DELIVERY_STREETS_BY_MASK, payload: [] });

    try {
        const response = await api.sendSms(data);
        if (JSON.parse(response.data.success) === true) {
            dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: true });
        } else {
            dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: false });
        }
    } catch (error) {
        console.error('Error sendSmsCode:', error);
        dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: false });
    }
};

const checkSmsCode = (data_1, data_2) => async (dispatch) => {
    try {
        const response = await api.validCode(9999999999, data_1, 1);

        if (response.data.success) {
            setCookie('auth', true);
            dispatch({ type: types.CHECKOUT_VALID_CODE, payload: true });
            dispatch(Actions.ActionsFor1C.setOrderDone(data_2));
        } else {
            dispatch({ type: types.CHECKOUT_VALID_CODE, payload: false });
        }
    } catch (error) {
        console.error('Error checkSmsCode:', error);
        dispatch({ type: types.CHECKOUT_VALID_CODE, payload: false });
    }
};

export default {
    getCityOrganizations,
    getCityOrganization,
    getSearchProductsName,
    getOrganizationContent,
    getOrganizationCategories,
    getOrganizationProducts,
    getOrganizationProduct,
    getStreetsByMask,
    sendSmsCode,
    checkSmsCode,
};