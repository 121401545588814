import * as types from '../types';
import Store from '../store';
import * as api from '../../api';
import Actions from './';
import { setCookie, getCookie } from '../../utils/cookies';

const setUserLocal = (userData) => ({
    type: 'SET_USER_LOCAL',
    payload: userData
});

const setSessionId = () => async () => {
    if (typeof window !== 'undefined') {
        try {
            if (!getCookie('session')) {
                const response = await api.getSession();
                if (response.data.success) {
                    setCookie('session', response.data.sessionId, 1);
                } else {
                    console.error('setSessionId:', response);
                }
            }
        } catch (error) {
            console.error('Error setSessionId:', error);
        }
    }
};

const getCartsFromServer = (cityGuid, allDataOrganizations = 0) => async (dispatch, getState) => {
    try {
        const state = getState();
        let totalPrice = 0, result, dataCurrent = state.AreaWork.carts;

        if (!state.AreaWork.carts || state.AreaWork.carts?.length === 0 ) {
            const response = await api.dataCart(getCookie('session'), cityGuid, undefined, undefined);
            if (response.data.success) {
                response.data.data.forEach(item => { totalPrice += item.sum; });
                totalPrice = totalPrice;
                result = response.data.data;
                dataCurrent = response.data.data;
            } else {
                console.error('getCartsFromServer:', response);
            }
        }

        if (allDataOrganizations === 1) {
            const guids = dataCurrent.map(item => item.organizationInfo.guid);
            const response = await api.dataCart(getCookie('session'), cityGuid, guids, 1);
            if (response.data.success) {
                response.data.data.forEach(item => { totalPrice += item.sum; });
                totalPrice = totalPrice;
                result = response.data.data;
            } else {
                console.error('getCartsFromServer:', response);
            }
        }

        dispatch({ type: types.CARTS_TOTAL_PRICE, payload: totalPrice });
        dispatch({ type: types.CARTS, payload: result });
    } catch (error) {
        console.error('Error getCartsFromServer:', error);
    }
};

const addToCart = (data_1, data_2, inCart = 0) => async (dispatch) => {
    try {
        const response = await api.addToCart(getCookie('session'), data_2, data_1.organizationId, data_1.productId, data_1.productCount, data_1.additions);

        if (inCart === 1) {
            dispatch(getCartsFromServer(data_2, 1, 1));
        } else {
            dispatch({ type: types.CARTS, payload: response.data.data });

            if (response.data.success && response.data.cart?.length > 0) {
                let totalPrice = 0;
                response.data.cart.forEach(item => { totalPrice += item.sum; });
                dispatch({ type: types.CARTS_TOTAL_PRICE, payload: totalPrice });
            } else {
                dispatch({ type: types.CARTS_TOTAL_PRICE, payload: null });
            }
        }
    } catch (error) {
        console.error('Error addToCart:', error);
    }
};

const getUserCheck = (data_1, data_2, data_3) => async (dispatch) => {
    try {
        const response = await api.userCheck(getCookie('session'), parseInt(data_2), data_1, data_3);
        if (response.data.success) {
            dispatch({ type: types.CHECKOUT_USER_ERROR, payload: null });
        } else {
            dispatch({ type: types.CHECKOUT_USER_ERROR, payload: response.data.data || 'User check failed' });
        }
    } catch (error) {
        console.error('Error getUserCheck:', error);
    }
};

const getUserData = () => async (dispatch, getState) => {
    const state = getState();

    if (state.Content.user.auth) {
        try {
            const response = await api.userData(getCookie('session'), 0);
            const userData = response.data.data;

            dispatch(setUserLocal(userData));

        } catch (error) {
            console.error('Error getUserData:', error);
        }
    }
};

const getPeriodsTime = (data_1, data_2) => async (dispatch, getState) => {
    dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_VALID_CODE, payload: null });
    dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });

    const state = getState();
    const cityLocal = state.City;

    try {
        const response = await api.periodsTime(getCookie('session'), cityLocal.guid, data_1, [data_2]);
        if (response.data.success) {
            dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
            dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: response.data.data });
        } else {
            dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: response.data.error });
            dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });
        }
    } catch (error) {
        console.error('Error getPeriodsTime:', error);
    }
};

const setOrderAddress = (data_1, data_2, data_3, data_4) => async (dispatch, getState) => {
    dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_VALID_CODE, payload: null });
    dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });

    const state = getState();
    const cityLocal = state.City;
    const cartOrganizationsKeys = state.AreaWork.carts.map((item) => item.organizationInfo.guid);

    try {
        const response = await api.orderAddress(getCookie('session'), cityLocal.guid, cartOrganizationsKeys, data_3, data_1, data_2);
        if (response.data.success) {
            dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
        } else {
            dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: response.data.error });
        }
    } catch (error) {
        console.error('Error setOrderAddress:', error);
    }
};

const setOrderDateTime = (data_1, data_2, data_3) => async (dispatch, getState) => {
    dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_VALID_CODE, payload: null });
    dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });

    const state = getState();
    const cityLocal = state.City;

    try {
        await api.orderDatetime(getCookie('session'), cityLocal.guid, data_1, data_2, data_3);
    } catch (error) {
        console.error('Error setOrderDateTime:', error);
    }
};

const setOrderAudit = (data) => async (dispatch, getState) => {
    dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
    dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: null });
    dispatch({ type: types.CHECKOUT_VALID_CODE, payload: null });
    dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });

    const state = getState();
    const cityLocal = state.City;
    const cartOrganizationsKeys = state.AreaWork.carts.map((item) => item.organizationInfo.guid);

    try {
        const response = await api.orderAudit(getCookie('session'), cityLocal.guid, cartOrganizationsKeys, data);
        if (response.data.success) {
            dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
            dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: true });
            dispatch(Actions.ActionsForServer.sendSmsCode(data.phone));
        } else {
            dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: response });
            dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: false });
        }
    } catch (error) {
        console.error('Error setOrderAudit:', error);
    }
};

const setOrderDone = (data) => async (dispatch) => {
    const cartOrganizationsKeys = Store.getState().AreaWork.carts.map((item) => item.organizationInfo.guid);

    try {
        const response = await api.orderDone(getCookie('session'), cartOrganizationsKeys);
        if (response.data.success) {
            dispatch({ type: types.CHECKOUT_ADDRESS_ERROR, payload: null });
            dispatch({ type: types.CHECKOUT_DATE_ERROR, payload: null });
            dispatch({ type: types.CHECKOUT_TIME_ERROR, payload: null });
            dispatch({ type: types.CHECKOUT_PERMISSION_SEND_SMS, payload: null });
            dispatch({ type: types.CHECKOUT_STATUS_SEND_SMS, payload: null });
            dispatch({ type: types.CHECKOUT_VALID_CODE, payload: null });
            dispatch({ type: types.CART_SET_DELIVERY_PERIODS_TIME, payload: [] });
            dispatch({ type: types.CARTS, payload: [] });
        } else {
            console.error('setOrderDone:', response);
        }
    } catch (error) {
        console.error('Error setOrderDone:', error);
    }
};

export default {
    setSessionId,
    getCartsFromServer,
    addToCart,
    getUserCheck,
    getUserData,
    getPeriodsTime,
    setOrderAddress,
    setOrderDateTime,
    setOrderAudit,
    setOrderDone,
};