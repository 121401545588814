import axios from 'axios'
import { createBrowserHistory } from 'history';

// Функция для получения токена из cookie
export const getCookie = (name) => {
    const nameEQ = name + '=';
    const docCookies = document.cookie.split(';');
    for (let i = 0; i < docCookies.length; i++) {
        let docCookie = docCookies[i];
        while (docCookie.charAt(0) === ' ') docCookie = docCookie.substring(1, docCookie.length);
        if (docCookie.indexOf(nameEQ) === 0) return docCookie.substring(nameEQ.length, docCookie.length);
    }
    return null;
};

const history = createBrowserHistory();

const apiURL = axios.create({
    baseURL: '/'
})

// Интерсептор для автоматического добавления токена ко всем запросам
apiURL.interceptors.request.use(
    (config) => {
        if (config.skipInterceptor) {
            return config;
        }
        const token = getCookie('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Интерсептор для обработки ошибок
apiURL.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            history.push('/');
        }
        return Promise.reject(error);
    }
);

export const getToken = () => apiURL.get('/token/create', { headers: {'Authorization': `Basic ${btoa('marketa:zM8GLZe@H}')}`} })

export const checkToken  = () => apiURL.get(`/token/check`)

export const allCities = () => apiURL.get('/cities')

export const cityByName = (name) => apiURL.get(`/cities/name/${name}`)

export const organizationsByCity = (cityGuid, limit, page, random, categoryGuid) => apiURL.get(`/organizations/city/${cityGuid}`,
    { params: {limit, page, random, categoryGuid } })

export const organizationByGuid = (guid) => apiURL.get(`/organizations/${guid}`)

export const organizationByName = (name) => apiURL.get(`/organizations/name/${name}`)

export const categoriesByOrganization = (guid) => apiURL.get(`/categories/organization/${guid}`)

export const productByGuid = (guid) => apiURL.get(`/products/${guid}`)

export const productsByOrganization = (guid, categoryGuid, parentCategoryGuid, productValue, limit, page) => apiURL.get(`/products/organization/${guid}`,
    { params: {categoryGuid, parentCategoryGuid, productValue, limit, page} })

export const contentByOrganization = (guid) => apiURL.get(`/contents/organization/${guid}`)

export const streetByName = (name, guid) => apiURL.get(`/addresses/city/${guid}/search`,
    { params: {name} })

export const periodsTime = (sessionGuid, cityGuid, date, organizations) => apiURL.get(`/api/v3/cities/${cityGuid}/intervals`,
    { params: {sessionId: sessionGuid, organizations, date} })

export const getSession = () => apiURL.get('/api/v3/sessions/create')

export const dataCart = (sessionGuid, cityId, organizations, details ) => apiURL.get(`/api/v3/carts/${sessionGuid}`,
    { params: {cityId, organizations, details} })

export const userData = (sessionGuid, phone) => apiURL.get(`/api/v3/users/phone/${phone}`,
    { params: {sessionId: sessionGuid} })

export const addToCart = (sessionGuid, cityGuid, organizationId, productGuid, productCount, additions) => apiURL.post(`/api/v3/carts/${sessionGuid}/update/products`,
    { cityId: cityGuid, organizationId, productId: productGuid, productCount, additions })

export const userCheck = (sessionGuid, phone, name, getSession) => apiURL.post(`/api/v3/carts/${sessionGuid}/update/user`,
    { phone, name, getSession })

export const orderAddress = (sessionGuid, cityGuid, organizationGuid, pickup, addressId, houseNumber) => apiURL.post(`/api/v3/carts/${sessionGuid}/update/address`,
    { cityId: cityGuid, organizations: [organizationGuid], pickup, addressId, houseNumber })

export const orderDatetime = (sessionGuid, cityGuid, organizationGuid, deliveryDate, deliveryTime ) => apiURL.post(`/api/v3/carts/${sessionGuid}/update/datetime`,
    { cityId: cityGuid, organizations: [organizationGuid], deliveryDate, deliveryTime  })

export const orderAudit = (sessionGuid, cityGuid, organizationGuid, data) => apiURL.post(`/api/v3/carts/${sessionGuid}/check`,
    { cityId: cityGuid, organizations: [organizationGuid], ...data})

export const orderDone = (sessionGuid, organizationGuid ) => apiURL.post(`/api/v3/carts/${sessionGuid}/create`,
    { organizations: [organizationGuid]})

export const sendSms = (phone) => apiURL.post('/sms/send',
    { phone })

export const validCode = (phone, code, type) => apiURL.post('/sms/valid',
    { phone, code, type })