import * as types from '../types'
import Actions from './'

const init = () => dispatch => {
    dispatch(Actions.ActionsFor1C.setSessionId())
}

const ActionsClean = {
    init
}

export default ActionsClean