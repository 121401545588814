import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Actions from '../redux/actions'
import { Link } from 'react-router-dom'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

const range = (from, to, step = 1) => {
    let i = from
    const range = []
    while (i <= to) {
        range.push(i)
        i += step
    }
    return range
}

class Pagination extends Component {
    constructor(props) {
        super(props)
        const { pageNeighbours = 1 } = props
        this.pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0
        this.state = { currentPage: this.props.pageIndex }
    }

    fetchPageNumbers = () => {
        const totalPages = Math.ceil(this.props.totalRecords  / this.props.pageLimit)
        const currentPage = this.state.currentPage
        const pageNeighbours = this.pageNeighbours
        const totalNumbers = (this.pageNeighbours * 2) + 3
        const totalBlocks = totalNumbers + 2

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours)
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
            let pages = range(startPage, endPage)

        const hasLeftSpill = startPage > 2
        const hasRightSpill = (totalPages - endPage) > 1
        const spillOffset = totalNumbers - (pages?.length + 1)

        switch (true) {
            // handle: (1) < {5 6} [7] {8 9} (10)
            case (hasLeftSpill && !hasRightSpill): {
                const extraPages = range(startPage - spillOffset, startPage - 1)
                pages = [LEFT_PAGE, ...extraPages, ...pages]
                break
            }

            // handle: (1) {2 3} [4] {5 6} > (10)
            case (!hasLeftSpill && hasRightSpill): {
                const extraPages = range(endPage + 1, endPage + spillOffset)
                pages = [...pages, ...extraPages, RIGHT_PAGE]
                break
            }

            // handle: (1) < {4 5} [6] {7 8} > (10)
            case (hasLeftSpill && hasRightSpill):
            default: {
                pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
                break
            }
        }

        return [1, ...pages, totalPages]
        }

        return range(1, totalPages)
    }

    render() {
        if (!this.props.totalRecords || Math.ceil(this.props.totalRecords  / this.props.pageLimit) === 1) return null
        const pages = this.fetchPageNumbers()
        const organizationInfo = this.props.organizationInfo ? this.props.organizationInfo : null
        const cityInfo = this.props.cityInfo ? this.props.cityInfo : null

        return (<Fragment>
            <ul className="pagination">
                { pages.map((page, index) => {
                    if (page === LEFT_PAGE) return (
                    <li key={index} className="page-item">{
                    this.props.organizationInfo ?
                        this.props.categoryGuid == null && this.props.parentCategoryGuid == null && this.props.productValue == null ?
                        <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/page/${Number(this.props.pageIndex)-1}`} key="left_page"
                        className="page-link" onClick={this.handleMoveLeft}>
                            <span aria-hidden="true">&#8249;</span>
                        </Link> :
                            this.props.categoryGuid == null && this.props.parentCategoryGuid == null ?
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/search/${this.props.productValue}/page/${Number(this.props.pageIndex)-1}`} key="left_page"
                            className="page-link" onClick={this.handleMoveLeft}>
                                <span aria-hidden="true">&#8249;</span>
                            </Link> :
                            this.props.parentCategoryGuid == null ?
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/${this.props.categoryGuid}/page/${Number(this.props.pageIndex)-1}`} key="left_page"
                            className="page-link" onClick={this.handleMoveLeft}>
                                <span aria-hidden="true">&#8249;</span>
                            </Link> :
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/${this.props.parentCategoryGuid}/page/${Number(this.props.pageIndex)-1}`} key="left_page"
                            className="page-link" onClick={this.handleMoveLeft}>
                                <span aria-hidden="true">&#8249;</span>
                            </Link>
                    :
                    this.props.cityCategoryGuid == null ?
                        <Link to={`/${cityInfo.transliteration}/shops/page/${Number(this.props.pageIndex)-1}`} key="left_page"
                        className="page-link" onClick={this.handleMoveLeft}>
                            <span aria-hidden="true">&#8249;</span>
                        </Link> :
                        <Link to={`/${cityInfo.transliteration}/shops/${this.props.cityCategoryGuid}/page/${Number(this.props.pageIndex)-1}`} key="left_page"
                        className="page-link" onClick={this.handleMoveLeft}>
                            <span aria-hidden="true">&#8249;</span>
                        </Link>
                    }</li>)

                    if (page === RIGHT_PAGE) return (
                    <li key={index} className="page-item"> {
                    this.props.organizationInfo ?
                    this.props.categoryGuid == null && this.props.parentCategoryGuid == null && this.props.productValue == null ?
                        <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/page/${Number(this.props.pageIndex)+1}`} key="right_page"
                        className="page-link" onClick={this.handleMoveRight}>
                            <span aria-hidden="true">&#8250;</span>
                        </Link> :
                            this.props.categoryGuid == null && this.props.parentCategoryGuid == null ?
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/search/${this.props.productValue}/page/${Number(this.props.pageIndex)+1}`} key="right_page"
                            className="page-link" onClick={this.handleMoveRight}>
                                <span aria-hidden="true">&#8250;</span>
                            </Link> :
                            this.props.parentCategoryGuid == null ?
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/${this.props.categoryGuid}/page/${Number(this.props.pageIndex)+1}`} key="right_page"
                            className="page-link" onClick={this.handleMoveRight}>
                                <span aria-hidden="true">&#8250;</span>
                            </Link> :
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/${this.props.parentCategoryGuid}/page/${Number(this.props.pageIndex)+1}`} key="right_page"
                            className="page-link" onClick={this.handleMoveRight}>
                                <span aria-hidden="true">&#8250;</span>
                            </Link>
                    :
                    this.props.cityCategoryGuid == null ?
                        <Link to={`/${cityInfo.transliteration}/shops/page/${Number(this.props.pageIndex)+1}`} key="right_page"
                        className="page-link" onClick={this.handleMoveRight}>
                            <span aria-hidden="true">&#8250;</span>
                        </Link> :
                        <Link to={`/${cityInfo.transliteration}/shops/${this.props.cityCategoryGuid}/page/${Number(this.props.pageIndex)+1}`} key="right_page"
                        className="page-link" onClick={this.handleMoveRight}>
                            <span aria-hidden="true">&#8250;</span>
                        </Link>
                    }</li>)

                    return (
                    this.props.organizationInfo ?
                        this.props.categoryGuid == null && this.props.parentCategoryGuid == null && this.props.productValue == null ?
                        <li key={index} className={`page-item${ this.props.pageIndex == page ? ' active' : ''}`}>
                            <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/page/${page}`} key={'page_'+index}
                            className="page-link" onClick={ this.handleClick(page) }>{ page }</Link>
                        </li> :
                            this.props.categoryGuid == null &&  this.props.parentCategoryGuid == null  ?
                            <li key={index} className={`page-item${ this.props.pageIndex == page ? ' active' : ''}`}>
                                <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/search/${this.props.productValue}/page/${page}`} key={'page_'+index}
                                className="page-link" onClick={ this.handleClick(page) }>{ page }</Link>
                            </li> :
                            this.props.parentCategoryGuid == null ?
                                <li key={index} className={`page-item${ this.props.pageIndex == page ? ' active' : ''}`}>
                                    <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/${this.props.categoryGuid}/page/${page}`} key={'page_'+index}
                                    className="page-link" onClick={ this.handleClick(page) }>{ page }</Link>
                                </li> :
                                <li key={index} className={`page-item${ this.props.pageIndex == page ? ' active' : ''}`}>
                                    <Link to={`/${cityInfo}/${organizationInfo.transliteration}/menu/${this.props.parentCategoryGuid}/page/${page}`} key={'page_'+index}
                                    className="page-link" onClick={ this.handleClick(page) }>{ page }</Link>
                                </li>
                    :
                    this.props.cityCategoryGuid == null ?
                        <li key={index} className={`page-item${ this.props.pageIndex == page ? ' active' : ''}`}>
                            <Link to={`/${cityInfo.transliteration}/shops/page/${page}`} key={'page_'+index}
                            className="page-link" onClick={ this.handleClick(page) }>{ page }</Link>
                        </li> :
                        <li key={index} className={`page-item${ this.props.pageIndex == page ? ' active' : ''}`}>
                            <Link to={`/${cityInfo.transliteration}/shops/${this.props.cityCategoryGuid}/page/${page}`} key={'page_'+index}
                            className="page-link" onClick={ this.handleClick(page) }>{ page }</Link>
                        </li>
                    )
                }) }
            </ul>
        </Fragment>)
    }

    componentDidMount() {
        this.gotoPage(this.props.pageIndex)
    }

    gotoPage = page => {
        const { onPageChanged = f => f } = this.props
        const currentPage = Math.max(0, Math.min(page, Math.ceil(this.props.totalRecords  / this.props.pageLimit)))
        const paginationData = {
            currentPage,
            totalPages: Math.ceil(this.props.totalRecords  / this.props.pageLimit),
            pageLimit: this.props.pageLimit,
            totalRecords: this.props.totalRecords
        }
        this.setState({ currentPage }, () => onPageChanged(paginationData))
    }

    handleClick = page => () => {
        if (this.props.organizationInfo) {
            const organizationInfo = this.props.organizationInfo
            this.gotoPage(page)
            this.props.getOrganizationProducts(organizationInfo.guid, this.props.categoryGuid, this.props.parentCategoryGuid, this.props.productValue, this.props.pageLimit, page-1)
        } else {
            this.gotoPage(page)
            this.props.getCityOrganizations(this.props.cityInfo.guid, this.props.pageLimit, page, 0, this.props.cityCategoryGuid)
        }
    }

    handleMoveLeft = () => {
        if (this.props.organizationInfo) {
            const organizationInfo = this.props.organizationInfo
            const page = this.state.currentPage - 1
            this.gotoPage(page)
            this.props.getOrganizationProducts(organizationInfo.guid, this.props.categoryGuid, this.props.parentCategoryGuid, this.props.productValue, this.props.pageLimit, page-2)
        } else {
            const page = this.state.currentPage - 1
            this.gotoPage(page)
            this.props.getCityOrganizations(this.props.cityInfo.guid, this.props.pageLimit, page-1, 0, this.props.cityCategoryGuid)
        }
    }

    handleMoveRight = () => {
        if (this.props.organizationInfo) {
            const organizationInfo = this.props.organizationInfo
            const page = this.state.currentPage + 1
            this.gotoPage(page)
            this.props.getOrganizationProducts(organizationInfo.guid, this.props.categoryGuid, this.props.parentCategoryGuid, this.props.productValue, this.props.pageLimit, page-1)
        } else {
            const page = this.state.currentPage + 1
            this.gotoPage(page)
            this.props.getCityOrganizations(this.props.cityInfo.guid, this.props.pageLimit, page, 0, this.props.cityCategoryGuid)
        }
    }
}

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    organization: state.AreaWork.organization,
    organizations: state.AreaWork.organizations,
    city: state.Content.city,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationProducts: (data_1, data_2, data_3, data_4, data_5, data_6) => {
            dispatch(Actions.ActionsForServer.getOrganizationProducts(data_1, data_2, data_3, data_4, data_5, data_6))
        },
        getCityOrganizations: (data_1, data_2, data_3, data_4, data_5) => {
            dispatch(Actions.ActionsForServer.getCityOrganizations(data_1, data_2, data_3, data_4, data_5))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)