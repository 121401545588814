import React, { Component } from 'react'

class Promotions extends Component {

    render(){
        return (
            <div>
                22222
            </div>
        )
    }
}

export default Promotions