import * as types from '../types';
import * as api from '../../api';

const setSelectedCity = (city) => ({
    type: types.SET_SELECTED_CITY,
    payload: city,
});

// Асинхронное действие для получения информации о городе по имени
const fetchCityByName = (cityName, limit, pageIndex, param1, param2) => async (dispatch) => {
    try {
        const response = await api.cityByName(cityName, limit, pageIndex, param1, param2);
        if (response.data.success) {
            const city = response.data.data;
            dispatch(setSelectedCity(city));
        } else {
            console.error('fetchCityByName:', response);
        }
    } catch (error) {
        console.error('Error fetchCityByName:', error);
    }
};

// Асинхронное действие для получения всех городов
const fetchCities = () => async (dispatch) => {
    try {
        const response = await api.allCities();
        if (response.data.success) {
            dispatch({ type: types.CONTENT_FETCH_CITIES, payload: response.data.data });
        } else {
            console.error('fetchCities:', response);
        }
    } catch (error) {
        console.error('Error fetchCities:', error);
    }
};

export default {
    setSelectedCity,
    fetchCityByName,
    fetchCities
}