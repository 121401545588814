import React, { Component } from 'react';
import { connect } from 'react-redux';
import Checkout from './elements/Checkout';
import CartItems from './elements/CartItems';
import Actions from '../../redux/actions';

class Cart extends Component {
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.city.guid !== prevProps.city.guid) {
            this.fetchData();
        }
    }

    fetchData() {
        const { city } = this.props;
        if (city && city.guid) {
            this.props.getUserData();
            this.props.getCartsFromServer(city.guid, 1);
        }
    }

    render() {
        const { carts, city } = this.props;

        return (
            <div className="body-container">
                <div className="container">
                    <div className="row">
                        <Checkout cartsInfo={carts} />
                        <CartItems city={city} />
                    </div>
                </div>
                <br/><br/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    carts: state.AreaWork.carts,
    cartsTotalPrice: state.AreaWork.cartsTotalPrice,
    city: state.Content.city,
});

const mapDispatchToProps = (dispatch) => ({
    getUserData: () => dispatch(Actions.ActionsFor1C.getUserData()),
    getCartsFromServer: (cityGuid, allDataOrganizations) =>
        dispatch(Actions.ActionsFor1C.getCartsFromServer(cityGuid, allDataOrganizations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);