import Home from './home/Home'
import Shop from './shop/Shop'
import Shops from './shops/Shops'
import Cart from './cart/Cart'
import SearchResult from './SearchResult'
import Success from './Success'
import NotFound from './NotFound'
import ApiInfo from './ApiInfo'

const Pages = {
    Home,
    Shop,
    Shops,
    Cart,
    SearchResult,
    Success,
    NotFound,
    ApiInfo,
}

export default Pages