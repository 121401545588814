import Header from './header/Header'
import Footer from './Footer'
import Pagination from './Pagination'
import TabsDelivery from './TabsDelivery'
import TextTrim from './TextTrim'
import Slider from './Slider'
import TreeData from './TreeData'

const Components = {
    Header,
    Footer,
    Pagination,
    TabsDelivery,
    TextTrim,
    Slider,
    TreeData
}

export default Components