import * as types from '../types';

const initialState = {};

function Cart(state = initialState, action = {}) {
    switch (action.type) {
        case types.CHECKOUT_USER_ERROR:
            return {
                ...state,
                checkoutUserError: action.payload,
            };
        case types.CHECKOUT_ADDRESS_ERROR:
            return {
                ...state,
                checkoutAddressError: action.payload,
            };
        case types.CHECKOUT_DATE_ERROR:
            return {
                ...state,
                checkoutDateError: action.payload,
            };
        case types.CHECKOUT_TIME_ERROR:
            return {
                ...state,
                checkoutTimeError: action.payload,
            };
        case types.CHECKOUT_PERMISSION_SEND_SMS:
            return {
                ...state,
                checkoutPermissionSendSms: action.payload,
            };
        case types.CHECKOUT_STATUS_SEND_SMS:
            return {
                ...state,
                checkoutStatusSendSms: action.payload,
            };
        case types.CHECKOUT_VALID_CODE:
            return {
                ...state,
                checkoutValidCode: action.payload,
            };
        case types.CART_SET_DELIVERY_PERIODS_TIME:
            return {
                ...state,
                deliveryPeriodsTime: action.payload,
            };
        case types.CART_SET_DELIVERY_STREETS_BY_MASK:
            return {
                ...state,
                deliveryStreetsByMask: action.payload,
            };
        default:
            return state;
    }
}

export default Cart;