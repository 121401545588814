import React, { useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import Actions from '../../redux/actions'

function ModalSms(props) {
    const checkoutValidCode = useSelector(state => state.Cart.checkoutValidCode, shallowEqual)
    const dataAudit = props.dataAudit
    const [inputCode, setInputCode] = useState(0)
    const dispatch = useDispatch()

    return (
        <div className="modal-container">
            <div className="modal-container-city">
                <div className="row">
                    <div className="col" ><br/>
                        Введите код из SMS, который пришел на указанный номер телефона.
                    </div>
                    <div className="col-1" >
                        <button className="btn btn-light" onClick={() => props.hide()}>&#10006;</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <input className="form-control" autoComplete={'off'} max="4" size="4"
                            onChange={(event) => {
                                if (event.target.value?.length == 4) {
                                    setInputCode(event.target.value)
                            }}}
                        />
                    </div>
                    <div className="col-2">
                        <button className="btn btn-light" onClick={() => {
                            if (inputCode?.length == 4) {
                                dispatch(Actions.ActionsForServer.checkSmsCode(Number(inputCode), dataAudit))
                                if (checkoutValidCode == true) {
                                    props.hide()
                                } else {
                                    <div><br/><br/><label className="alert alert-danger"> Неверный код подтверждения!</label></div>
                                }
                            }
                        }}>
                            Отправить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSms