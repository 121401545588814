import React from 'react'
import Images from '../../../assets/images'

export default function Suggestions() {
    return (
        <div>
            <div style={{paddingLeft: '1em', paddingRight: '1em', display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr', gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )', gridGap: 50, width: '100%', height: '100%'}}>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 30}}>
                    <div className="suggestions-image"><img src={Images.IconBuyCard} /></div>
                    <div><br/>
                        <label className="h4">Огромный выбор</label>
                        <label className="text-muted">100 ресторанов и магазинов в Томске с доставкой</label>
                    </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 30}}>
                    <div className="suggestions-image"><img src={Images.IconLocation} /></div>
                    <div><br/>
                        <label className="h4">Экономьте время</label>
                        <label className="text-muted">Все ваши любимые товары на расстоянии вытянутой руки</label>
                    </div>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 30}}>
                    <div className="suggestions-image"><img src={Images.IconTimeCircle} /></div>
                    <div><br/>
                        <label className="h4">60 минут</label>
                        <label className="text-muted">Среднее время доставки</label>
                    </div>
                </div>
            </div><br/><br/><br/><br/>
            <div className="aboutBlock">
                <h3 style={{marginBottom:52}}>ЕДИНЫЙ СЕРВИС ЗАКАЗОВ И ДОСТАВКИ МАРКЕТА В ТОМСКЕ</h3>
                <div style={{lineHeight: 2.5}}>Маркета существует, чтобы объединить в Томске предложения кафе и ресторанов, служб доставки еды, цветов, товаров и
                    услуг в одном месте. Для вас мы разработали удобный сервис, позволяющий выбрать понравившиеся блюда, товары и заказать для самовывоза, либо доставки их к себе домой или на работу. Некоторые партнеры осуществляют бесплатную доставку и работают по 24 часа в сутки, поэтому вам не придется задаваться вопросом: - <span className="bold">"Где можно заказать еду круглосуточно?"</span> Наш сервис уже позаботился об этом и собрал все лучшие и выгодные предложения в одном месте. Также наш сервис поможет забронировать столик в кафе и ресторане в Томске.
                </div><br/><br/>
                <div style={{lineHeight: 2.5}}>Несомненным плюсом работы единого сервиса по доставке еды, цветов и товаров является экономия времени – все заказы после оформления моментально передаются заведениям-партнерам, поэтому доставка осуществляется максимально быстро и с выгодой для вас, ведь через сервис еду, цветы и товары можно заказать дешевле, чем в самом заведении. Так при совершении заказов вы получаете <span className="bold">кэшбэк до 20%</span>, и в дальнейшем можете оплачивать ими покупки. Зарегистрируйся и получи <span className="bold">200 бонусных рублей в подарок на первый заказ</span>, которыми сразу можно <span className="bold">рассчитаться до 50% стоимости заказа!</span>
                </div>
            </div>

        </div>
    )
}
