import * as types from '../types';

const initialState = {
    organizations: [],
    organization: null,
    organizationsQuantity: 0,
    organizationContents: [],
    organizationCategories: [],
    organizationProducts: [],
    organizationProductsQuantity: 0,
    organizationProduct: null,
    searchInsideOrganizationMenuResult: [],
    findedResultsOutputSortingMode: null,
    carts: [],
    cartsTotalPrice: 0,
};

function AreaWork(state = initialState, action = {}) {
    switch (action.type) {
        case types.AREA_CITY_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION:
            return {
                ...state,
                organization: action.payload,
            };
        case types.AREA_CITY_ORGANIZATIONS_QUANTITY:
            return {
                ...state,
                organizationsQuantity: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_CONTENTS:
            return {
                ...state,
                organizationContents: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_CATEGORIES:
            return {
                ...state,
                organizationCategories: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_PRODUCTS:
            return {
                ...state,
                organizationProducts: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_PRODUCTS_QUANTITY:
            return {
                ...state,
                organizationProductsQuantity: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_PRODUCT:
            return {
                ...state,
                organizationProduct: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_MENU_INSIDE_SEARCH:
            return {
                ...state,
                searchInsideOrganizationMenuResult: action.payload,
            };
        case types.AREA_CITY_ORGANIZATION_MENU_INSIDE_SEARCH_SORTING_MODE:
            return {
                ...state,
                findedResultsOutputSortingMode: action.payload,
            };
        case types.CARTS:
            return {
                ...state,
                carts: action.payload,
            };
        case types.CARTS_TOTAL_PRICE:
            return {
                ...state,
                cartsTotalPrice: action.payload,
            };
        default:
            return state;
    }
}

export default AreaWork;