
import HomeBasket from './basket.png'
import UserIcon from './default.png'
import E404 from './e404.png'
import Feedback from './feedback.png'
import Group from './Group.png'
import MainLogo from './logo.png'
import HomePhone from './phone.png'
import Union from './Union.png'
import IconAdd from './icons/Add.png'
import IconApple from './icons/apple.png'
import IconGooglePlay from './icons/google play.png'
import IconBuy from './icons/Buy.png'
import IconBuyCard from './icons/BuyCard.png'
import IconCalendar from './icons/Calendar.jpg'
import IconCall from './icons/call.png'
import IconFb from './icons/fb.png'
import IconInst from './icons/inst.png'
import IconOk from './icons/OK.png'
import IconVk from './icons/vk.png'
import IconLocation from './icons/Location.png'
import IconLock from './icons/Lock.png'
import IconSearch from './icons/Search.png'
import IconTimeCircle from './icons/Time Circle.png'
import OrganizationDelivery from './Company/bike 1.png'
import OrganizationClock from './Company/price.png'
import OrganizationPhone from './Company/Call.png'
import Filter from './Filter.png'

const Images = {
    HomeBasket,
    UserIcon,
    E404,
    Feedback,
    Group,
    MainLogo,
    HomePhone,
    Union,
    IconAdd,
    IconApple,
    IconGooglePlay,
    IconBuy,
    IconBuyCard,
    IconCalendar,
    IconCall,
    IconFb,
    IconInst,
    IconOk,
    IconVk,
    IconLocation,
    IconLock,
    IconSearch,
    IconTimeCircle,
    OrganizationDelivery,
    OrganizationClock,
    OrganizationPhone,
    Filter
}

export default Images