import React, { Component } from 'react'
import Actions from '../../redux/actions'
import { connect } from 'react-redux'
import Information from './elements/Information'
import Promotions from './elements/Promotions'
import ProductsSearch from './elements/ProductsSearch'
import Categories from './elements/Categories'
import Products from './elements/Products'
import Components from '../../components'
import Filter from './elements/Filter'
import { Link } from 'react-router-dom'
import Images from '../../assets/images'

class Shop extends Component {
    constructor (props) {
        super(props)
        this.state = {
            activeNavbar: 1,
        }
        const categoryGuid = this.props.match.params.categoryGuid ? this.props.match.params.categoryGuid : null
        const parentCategoryGuid = this.props.match.params.parentCategoryGuid ? this.props.match.params.parentCategoryGuid : null
        const productValue = this.props.match.params.productValue ? this.props.match.params.productValue : null
        this.props.getCityOrganization(this.props.match.params.shopName, categoryGuid, parentCategoryGuid, productValue, this.props.match.params.pageIndex)
    }

    callbackFunction = (item) => {
        const cityName = this.props.match.params.cityName ? this.props.match.params.cityName : null
        const newUrl = '/'+cityName+'/'+this.props.organization.transliteration+'/menu/search/'+item+'/page/'+1
        this.props.history.push(newUrl)
    }

    render () {
        const cityName = this.props.match.params.cityName ? this.props.match.params.cityName : null
        const shopName = this.props.match.params.shopName ? this.props.match.params.shopName : null
        const organization = this.props.organization ? this.props.organization : null

        const navbarActive = event => {
            this.setState({activeNavbar: event.target.getAttribute('id')})
        }

        return (
            <div className="body-container">
                <div className="container">
                    <div className="shop-header row">
                        <div className="shop-header-image col">
                            {organization ? <img src={`${'/uploads/images/events/'+organization.logo}`} /> : null}
                        </div>
                        <div className="col-xs-12 col-sm-8 col-md-8">
                            <div className="shop-header-name">{organization ? organization.name : null}</div>
                            <div className="shop-header-info">
                                <div>
                                    <img src={Images.OrganizationDelivery} className="nav-logo"/><br/>
                                    <label className="text-muted">бесплатная доставка</label><br/><span className="text-muted">от </span>
                                    {organization ? organization.deliveryParameters.freeDeliverSum+' руб' : null}
                                </div>
                                <div>
                                    <img src={Images.OrganizationClock} className="nav-logo"/><br/>
                                    <label className="text-muted">время работы</label><br/>
                                    {organization ? organization.workingHours.map((item, index) => {
                                        if (index == new Date().getDay() - 1) {
                                            return <span key={'working_hours'+index}>с {item.from} до {item.to}</span>
                                        }
                                    }) : null}
                                </div>
                                <div>
                                    <img src={Images.OrganizationPhone} className="nav-logo"/><br/>
                                    <label className="text-muted">номер телефона</label><br/>
                                    {organization ? organization.numbers.deliveryNumber.substr(0, 3)+'-'+
                                    organization.numbers.deliveryNumber.substr(3) : null}
                                </div>
                            </div>
                        </div>
                    </div><br/>
                    {this.props.organizationContents ? <Components.Slider items={this.props.organizationContents}
                        pathImage="/uploads/images/events/"/> : null}

                    <nav className="shop-navbar navbar navbar-expand-md navbar-light">
                        <Link className={(this.state.activeNavbar === 0 ? ' active' : '')+' text-dark'} onClick={navbarActive} id="0"
                            to={'/'+cityName+'/'+shopName+'/information'}>
                            Информация
                        </Link>
                        <Link className={(this.state.activeNavbar === 1 ? ' active' : '')+' text-dark'} onClick={navbarActive} id="1"
                            to={'/'+cityName+'/'+shopName+'/menu/page/'+1}>
                            Меню
                            <label className={(this.state.activeNavbar === 1 ? ' active' : '')}></label>
                        </Link>
                        <Link className={(this.state.activeNavbar === 2 ? ' active' : '')+' text-dark'} onClick={navbarActive} id="2"
                            to={'/'+cityName+'/'+shopName+'/promotions'}>
                            Акции
                        </Link>
                        <div className="header-toggler navbar-toggler" data-toggle="collapse" data-target="#navbar">
                            <Filter cityInfo={cityName} organizationInfo={organization} parentCallback={this.callbackFunction} categoriesInfo={this.props.organizationCategories}  />
                        </div>
                    </nav>
                </div><hr/><br/>
                <div className="container">
                    {this.props.match.params.navbarName == 'information' ?
                        <Information organizationInfo={organization} />
                    : null}
                    {this.props.match.params.navbarName == 'menu' ?
                        <div className="row">
                            <div className="d-none d-md-block col">
                                <ProductsSearch cityInfo={cityName} organizationInfo={organization} parentCallback={this.callbackFunction} /><br/>
                                <Categories cityInfo={cityName} organizationInfo={organization} categoriesInfo={this.props.organizationCategories} />
                            </div>
                            <div className="col-sm col-md col-lg-9">
                                <Products cityInfo={cityName} organizationInfo={organization} productsInfo={this.props.organizationProducts} />
                                {this.props.organizationProductsQuantity > 4 ?
                                    <Components.Pagination cityInfo={cityName} organizationInfo={organization}
                                        totalRecords={this.props.organizationProductsQuantity} pageLimit={4}
                                        pageIndex = {this.props.match.params.pageIndex}
                                        categoryGuid = {this.props.match.params.categoryGuid ? this.props.match.params.categoryGuid : null}
                                        parentCategoryGuid = {this.props.match.params.parentCategoryGuid ? this.props.match.params.parentCategoryGuid : null}
                                        productValue = {this.props.match.params.productValue ? this.props.match.params.productValue : null}
                                    />
                                : null}
                            </div>
                        </div>
                    : null}
                    {this.props.match.params.navbarName == 'promotions' ?
                        <Promotions organizationInfo={organization} />
                    : null}
                </div><br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    organization: state.AreaWork.organization,
    organizationContents: state.AreaWork.organizationContents,
    organizationCategories: state.AreaWork.organizationCategories,
    organizationProducts: state.AreaWork.organizationProducts,
    organizationProductsQuantity: state.AreaWork.organizationProductsQuantity,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getCityOrganization: (data_1, data_2, data_3, data_4, data_5) => {
            dispatch(Actions.ActionsForServer.getCityOrganization(data_1, data_2, data_3, data_4, data_5))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop)