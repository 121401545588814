import React, { Component, Fragment} from 'react'
import { connect } from 'react-redux'
import Actions from '../../../redux/actions'
import { Link } from 'react-router-dom'
import Components from '../../../components'

class Categories extends Component {
    constructor (props) {
        super(props)
        this.state = {
            activeCategory: 'none',
            activeSubcategory: null,
        }
    }
    render(){
        const categoryActive = event => {
            this.setState({activeSubcategory: null})
            this.setState({activeCategory: event.target.getAttribute('id')})
        }

        const subcategoryActive = event => {
            this.setState({activeCategory: null})
            this.setState({activeSubcategory: event.target.getAttribute('id')})
        }
        return (
            <ul className="shop-categories">
                {this.props.organizationInfo &&
                    <Link className="text-dark" key="category_link_none"
                    onClick={() => {this.props.getOrganizationProducts(this.props.organizationInfo.guid, null, null, null, 4, 0)}}
                    to={'/'+this.props.cityInfo+'/'+this.props.organizationInfo.transliteration+'/menu/page/'+1}>
                        <li className={'shop-category-link'+(this.state.activeCategory == 'none' ? ' active' : '')} key="category_none"
                        onClick={categoryActive} id="none">
                            Все товары
                        </li>
                    </Link>
                }
                {Array.isArray(this.props.categoriesInfo) && this.props.categoriesInfo?.length > 0 &&
                    (Components.TreeData(this.props.categoriesInfo, 'guid', 'parentId').map((item, index) => {
                    return (
                        item.children?.length > 0 ?
                            <Fragment key={'category_'+index}>
                                <Link className="text-dark" key={'category_link_'+index}
                                onClick={() => {this.props.getOrganizationProducts(this.props.organizationInfo.guid, null, item.guid, null, 4, 0)}}
                                to={'/'+this.props.cityInfo+'/'+this.props.organizationInfo.transliteration+'/menu/'+item.guid+'/page/'+1}>
                                    <li className={'shop-category-link'+(this.state.activeCategory == index ? ' active' : '')} key={'category_li_'+index}
                                    onClick={categoryActive} id={index}>
                                        {item.name}
                                    </li>
                                </Link>
                                <ul>{item.children.map((item_2, index_2) => {return (
                                    <Link className="text-dark" key={'subcategory_link_'+index_2}
                                    onClick={() => {this.props.getOrganizationProducts(this.props.organizationInfo.guid, item_2.guid, null, null, 4, 0)}}
                                    to={'/'+this.props.cityInfo+'/'+this.props.organizationInfo.transliteration+'/menu/'+item_2.guid+'/page/'+1}>
                                        <li className={'shop-subcategory-link'+(this.state.activeSubcategory == index_2 ? ' active' : '')} key={'subcategory_li_'+index_2}
                                        onClick={subcategoryActive} id={index_2}>
                                            {item_2.name}
                                        </li>
                                    </Link>
                                )})} </ul>
                            </Fragment> :
                        <Link className="text-dark" key={'category_link_'+index}
                        onClick={() => {this.props.getOrganizationProducts(this.props.organizationInfo.guid, item.guid, null, null, 4, 0)}}
                        to={'/'+this.props.cityInfo+'/'+this.props.organizationInfo.transliteration+'/menu/'+item.guid+'/page/'+1}>
                            <div className={'shop-category-link'+(this.state.activeCategory == index ? ' active' : '')} key={'category_li_'+index}
                            onClick={categoryActive} id={index}>
                                {item.name}
                            </div>
                        </Link>
                    )
                }))}
            </ul>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    organizationProducts: state.AreaWork.organizationProducts,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationProducts: (data_1, data_2, data_3, data_4, data_5, data_6) => {
            dispatch(Actions.ActionsForServer.getOrganizationProducts(data_1, data_2, data_3, data_4, data_5, data_6))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Categories)