import React, { Component } from 'react'
import { connect } from 'react-redux'
import Actions from '../../../redux/actions'
import { Link } from 'react-router-dom'
import { getCookie } from '../../../utils/cookies';

class CartItems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cart: props.carts,
            totalSumToPay: 0
        }
    }

    handleAddToCart(data_1, data_2, data_3, data_4) {
        const data = {
            sessionId: getCookie('session') || '',
            organizationId: data_1,
            productId: data_2,
            productCount: data_3,
            additions: data_4
        }
        this.props.addToCart(data, this.props.city.guid, 1)
    }

    render() {
        this.state.totalSumToPay = 0
        return (
            <div className="col-lg">
                <div className="cart-item-main-title">Ваш заказ</div>
                {this.props.carts && this.props.carts?.length > 0 ? (
                    <div className="carts">
                        {this.props.carts.map((item, index) => {
                            this.state.totalSumToPay += item.sum
                            return (item.organizationInfo && (
                                <div key={'organization_' + index}>
                                    <div className="cart-title-organization" >
                                        <span>{item.productsCount}</span>
                                        <label className="cart-organization-name">{item.organizationInfo.name}</label>
                                        <span>{item.sum} руб</span>
                                    </div><br />
                                    {item.cart && item.cart.map((item_2, index_2) => {
                                        return (
                                            <div className="cart" key={'product_' + index_2}>{(item_2.productInfo != null) &&
                                                (<div className="row">
                                                    <div className="col-xs-7 col-sm-3 col-md-3">
                                                        <div className="cart-product-image">
                                                            <img src={'/uploads/images/products/' + item_2.productInfo.image} alt={item_2.productInfo.name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-10 col-sm-6 col-md-6">
                                                        <Link to={this.props.city.transliteration + '/' + item.organizationInfo.transliteration + '/' + item_2.productInfo.guid}
                                                            className="text-dark" key={'link_' + index_2}>
                                                            <label>{item_2.productInfo.name}, <span className="text-muted">{item_2.productInfo.weight} гр</span></label></Link>
                                                        {item_2.additions != [] && item_2.additions.map((item_3, index_3) => {
                                                            return (<label className="text-muted" key={'addition_' + index_3}>{item_3.productCount} x {item_3.productInfo.name}, {item_3.productInfo.weight} гр - <span>
                                                                {item_3.productInfo.price} руб</span></label>)
                                                        })}
                                                        <div className="cart-add-product">
                                                            {(item_2.productCount > 1) ? <button className="cart-add-button-product btn btn-light"
                                                                onClick={() => {
                                                                    const additionsAdd = []
                                                                    if (item_2.additions != []) {
                                                                        item_2.additions.map((item_4) => {
                                                                            additionsAdd.push({ productId: item_4.productInfo.guid, productCount: item_4.productCount })
                                                                        })
                                                                    }
                                                                    this.handleAddToCart(item.organizationInfo.guid, item_2.productInfo.guid, -1, additionsAdd)
                                                                }}>— </button> : <label className="cart-add-button-product"></label>}
                                                            {(item_2.productCount && item_2.productCount > 0) ? item_2.productCount : 1}
                                                            <button className="cart-add-button-product btn btn-light" onClick={() => {
                                                                const additionsAdd = []
                                                                if (item_2.additions != []) {
                                                                    item_2.additions.map((item_4) => {
                                                                        additionsAdd.push({ productId: item_4.productInfo.guid, productCount: item_4.productCount })
                                                                    })
                                                                }
                                                                this.handleAddToCart(item.organizationInfo.guid, item_2.productInfo.guid, 1, additionsAdd)
                                                            }}>+</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-7 col-sm-3 col-md-3">
                                                        <div className="cartitems-right-block row">
                                                            <div className="col">
                                                                <button className="btn btn-light" onClick={() => {
                                                                    const additionsAdd = []
                                                                    if (item_2.additions != []) {
                                                                        item_2.additions.map((item_4) => {
                                                                            additionsAdd.push({ productId: item_4.productInfo.guid, productCount: item_4.productCount })
                                                                        })
                                                                    }
                                                                    this.handleAddToCart(item.organizationInfo.guid, item_2.productInfo.guid, -item_2.productCount, additionsAdd)
                                                                }}>&#10006;</button>
                                                            </div>
                                                        </div><br /><br /><br />
                                                        <div className="cartitems-right-block row">
                                                            <div className="col">{item_2.productInfo.price} руб</div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                            <hr /></div>
                                        )
                                    })}
                                    <div className="cart-price-info">
                                        <label>Стоимость<br />Доставка<br />Акции<br />Итого</label>
                                        <label className="price">
                                            {item.cartPrice} руб<br />
                                            {item.deliveryPrice == 0 ? 'Бесплатная' : item.deliveryPrice + ' руб'}<br />
                                            {item.discountSum == 0 ? 'Отсутсвуют' : item.discountSum + ' руб'}<br />
                                            {item.sum} руб
                                        </label>
                                    </div><br />
                                </div>))
                        })}
                        <div className="total-price-info">
                            <label>ИТОГО</label>
                            <label>{this.state.totalSumToPay} руб</label>
                        </div>
                    </div>) : "В корзине пока ничего"
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    carts: state.AreaWork.carts,
    cartsTotalPrice: state.AreaWork.cartsTotalPrice,
    city: state.Content.city,
})

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (data_1, data_2, data_3) => {
            dispatch(Actions.ActionsFor1C.addToCart(data_1, data_2, data_3))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItems)