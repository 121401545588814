import React, { useState } from 'react'
import Images from '../../../assets/images'
import ProductsSearch from './ProductsSearch'
import Categories from './Categories'

function Filter(props) {
    const [selectVisible, setSelectVisible] = useState(0)
    return(
        <div>
            <div onClick={() => setSelectVisible(selectVisible + 1)}>
                <img src={Images.Filter}/>
            </div>
            { selectVisible ?
            <div className="modal-container">
                <div className="modal-container-product">
                    <button className="btn btn-light" onClick={() => setSelectVisible(false)}>&#10006;</button><br/><br/><br/><br/>
                    <ProductsSearch cityInfo={props.cityInfo} organizationInfo={props.organizationInfo} parentCallback={props.parentCallback}/><br/>
                    <Categories cityInfo={props.cityInfo} organizationInfo={props.organizationInfo} categoriesInfo={props.categoriesInfo}/>
                </div>
            </div> : null }
        </div>
    )
}

export default Filter