import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Actions from '../../../redux/actions';
import Images from '../../../assets/images';
import Components from '../../../components';
import ModalProduct from '../ModalProduct';

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            selectedProduct: null
        };
    }

    handleAddToCart = (item) => {
        const data = {
            organizationId: item.organizationGuid,
            productId: item.guid,
            productCount: 1,
            additions: [],
        };
        this.props.addToCart(data, this.props.city.guid);
    }

    handleOpenModal = (item) => {
        this.setState({ modalVisible: true, selectedProduct: item });
        this.props.getOrganizationProduct(item.guid);
    }

    render() {
        const { productsInfo, organizationInfo, cityName } = this.props;
        const { modalVisible, selectedProduct } = this.state;

        return (
            <div className="grid-container">
                {Array.isArray(productsInfo) && productsInfo?.length > 0 &&
                    productsInfo.map((item) => (
                        <div className="card-product" key={item.guid}>
                            {item.specifications[0] && item.specifications[0].composition?.length > 0 ? (
                                <img
                                    className="button-add-to-cart"
                                    src={Images.IconAdd}
                                    onClick={() => this.handleOpenModal(item)}
                                    alt="Add to Cart"
                                />
                            ) : (
                                <img
                                    className="button-add-to-cart"
                                    src={Images.IconAdd}
                                    onClick={() => this.handleAddToCart(item)}
                                    alt="Add to Cart"
                                />
                            )}
                            <Link
                                to='#'
                                onClick={() => this.handleOpenModal(item)}
                            >
                                <div className="product-image">
                                    {item.image && <img src={`/uploads/images/products/${item.image}`} alt={item.name} />}
                                </div>
                                <div className="product-info">
                                    <div className="product-name">
                                        {item.name}, <span className="text-muted">{item.weight} гр</span>
                                    </div>
                                    <div>{Components.TextTrim(80, item.description)}</div>
                                </div>
                                <div className="product-price">
                                    {item.price} руб
                                    {item.fakeprice > 0 && (
                                        <label className="product-fake-price text-muted">
                                            {item.fakeprice} руб
                                        </label>
                                    )}
                                </div>
                            </Link>
                        </div>
                    ))
                }
                {modalVisible && selectedProduct && (
                    <ModalProduct
                        hide={() => this.setState({ modalVisible: false, selectedProduct: null })}
                        organization={organizationInfo.guid}
                        product={selectedProduct}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    city: state.Content.city,
    organizationProduct: state.AreaWork.organizationProduct,
});

const mapDispatchToProps = (dispatch) => ({
    addToCart: (data, cityGuid) => dispatch(Actions.ActionsFor1C.addToCart(data, cityGuid)),
    getOrganizationProduct: (productId) => dispatch(Actions.ActionsForServer.getOrganizationProduct(productId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);