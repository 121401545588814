import React, { Component } from 'react'

class SearchResult extends Component {

    render() {
        return (
            <div>
    222
            </div>
        )
    }
}

export default SearchResult