function TextTrim(limit, text) {
    if (text.length > limit) {
        var textTrim = text.slice(0,limit)
        var newText = textTrim.split(' ')
        newText.splice(newText.length-1,1)
        textTrim = newText.join(' ')
        return textTrim+' ...'
    } else {
        return text
    }
}

export default TextTrim