import ActionsClean from './ActionsClean'
import ActionsFor1C from './ActionsFor1c'
import ActionsForServer from './ActionsForServer'
import ActionsCity from './ActionsCity'

const Actions = {
    ActionsClean,
    ActionsFor1C,
    ActionsForServer,
    ActionsCity
}
export default Actions